import React, { useRef } from "react";
import "./OrderDetailsPopUp.scss";

function OrderDetailsPopup({ setOpenModal, messageToDisplay }) {
  const modalRef = useRef();

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setOpenModal(false);
    }
  };
  //console.log(messageToDisplay)


  return (
    <div onClick={closeModal} ref={modalRef} className="OrderDetailsPopUp-modalBackground">
      <div className="OrderDetailsPopUp-modalContainer" >
        {/* <div className="custom-alert-box-name" onClick={closeModal}>X</div> */}
        <div className="OrderDetailsPopUp-modalContainer__box-msg">{messageToDisplay.message}</div>
        {/* <div className="InventoryUpdatePopup-modalContainer__box-button-container">
          <div onClick={() => { modalRef.current.click(); }} id="cancelBtn">Cancel</div>
          <div onClick={() => { messageToDisplay.func(); }} id="OkayBtn">OK</div>
        </div> */}
      </div>
    </div>
  );
}

export default OrderDetailsPopup;
