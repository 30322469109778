import axios from "axios";
import React, { useState } from "react";
import { backEndIP } from "../configuration";
import "./DeleteForm.scss"

const DeleteItem = ({ props, setShowConform, SetLoadingVisibility, userCookie, handleNotifyAlert, setListItems }) => {
    const itemToBeDeleted = {
        _id: props._id,
        name: props.name,
        filedata: props.image_name,
        description: props.description,
        available_quantity: props.available_quantity,
        weight: props.weight,
        price: props.price,
        category: props.category,
    }
    const handleCancel = ()=>{
        setShowConform(false);
    }
    const handelDeleteOk = (_id) => {
      //  console.log("Item Id",_id)
        SetLoadingVisibility(true)
        axios({
          url: backEndIP + `/inventory/del-item-inventory/${_id}`,
          method: "DELETE",
          headers: {
            authorization: userCookie.token,
          },
        }).then((data) => {
          SetLoadingVisibility(false)
          setShowConform(false);
          handleNotifyAlert(
            "notify",
            `Item Deleted successfully`,
            "success",
            () => { }
          );
         // console.log(data.data.data)
          //SetLoadingVisibility(false)
          setListItems(data.data.data)
          //window.location.reload(false);
        })
          .catch((err) => {
            //console.log(err);
            SetLoadingVisibility(false);
            setShowConform(false);
            handleNotifyAlert(
              "notify",
              `${err.response.message}`,
              "failed",
              () => { }
            );
          });
      }
    return (
        <div className="DeleteFormContainer">
            {/* <img className="DeleteFormContainer__custom-alert-box-img" src="AlertBoxIcon.svg" alt="" /> */}
            <div className="DeleteFormContainer__DeleteHeading">Are You Sure! You want to Delete This item?</div>
            <div className="DeleteFormContainer__container">
                <div className="DeleteFormContainer__container__imageSide">
                    <div className="DeleteFormContainer__container__delete-img-container">
                        {/* <div className="DeleteFormContainer__delete-img-container__lable">Image: </div> */}
                        <img src={backEndIP + "/" + itemToBeDeleted.filedata} alt="item Image" className="DeleteFormContainer__container__delete-img-container__image" />
                    </div>
                </div>
                <div className="DeleteFormContainer__container__contentSide">
                    <div className="DeleteFormContainer__container__content">
                        <div className="DeleteFormContainer__container__content__subContainer">
                            <div className="DeleteFormContainer__container__content__lable">Name</div>
                            <div className="DeleteFormContainer__container__content__colon">:</div>
                            <div className="DeleteFormContainer__container__content__itemDataFiled">{itemToBeDeleted.name}</div></div>
                            <div className="DeleteFormContainer__container__content__subContainer">
                            <div className="DeleteFormContainer__container__content__lable">Category</div>
                            <div className="DeleteFormContainer__container__content__colon">:</div>
                            <div className="DeleteFormContainer__container__content__itemDataFiled">{itemToBeDeleted.category}</div></div>
                        <div className="DeleteFormContainer__container__content__subContainer">
                            <div className="DeleteFormContainer__container__content__lable">Quantity</div>
                            <div className="DeleteFormContainer__container__content__colon">:</div>
                            <div className="DeleteFormContainer__container__content__itemDataFiled">{itemToBeDeleted.available_quantity}</div></div>
                            <div className="DeleteFormContainer__container__content__subContainer">
                        <div className="DeleteFormContainer__container__content__lable">Weight</div>
                        <div className="DeleteFormContainer__container__content__colon">:</div>
                        <div className="DeleteFormContainer__container__content__itemDataFiled">{itemToBeDeleted.weight}</div></div>
                        {/* <div className="DeleteFormContainer__container__content__subContainer">
                        <div className="DeleteFormContainer__container__content__lable">Description</div>
                        <div className="DeleteFormContainer__container__content__colon">:</div>
                        <div className="DeleteFormContainer__container__content__itemDataFiled">{itemToBeDeleted.description}</div></div> */}
                    </div>
                </div>  
            </div>
            <div className="DeleteFormContainer__Options">
            <div onClick={handleCancel} id="cancelBtn">Cancel</div>
            <div onClick={()=>handelDeleteOk(itemToBeDeleted._id)} id="OkayBtn">OK</div>
            </div>
        </div>
    )
}
export default DeleteItem;