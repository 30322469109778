import React, { useEffect, useRef, useState } from "react";

import "./ListDownOrderItems.scss";

const ListDownOrderItems = ({ item, index }) => {
  const InputQuantityRef = useRef();
  const [totalQuantity, setTotalQuantity] = useState(
    item.quantity * item.weight
  );
  const [totalPrice, setTotalPrice] = useState(item.quantity * item.price);

  useEffect(() => {
    setTotalQuantity(item.quantity * item.weight);
    setTotalPrice(item.quantity * item.price);
  }, [item.quantity]);


  return (
    <div className="ListDownItem-container">
      <div className="ListDownItem-container__Sr-No">{index + 1}</div>
      <div className="ListDownItem-container__name">{item.name}</div>
      <div className="ListDownItem-container__unit-price">
        {/* Rs {item.price} */}
      </div>
      <div className="ListDownItem-container__weight">{item.weight}g</div>
      <div className="ListDownItem-container__Volumetricweight">
        {item.weight}g
      </div>
      <div className="ListDownItem-container__quantity">
          {item.quantity}
      </div>
      <div className="ListDownItem-container__totalWeight">
        {totalQuantity}g
      </div>
      <div className="ListDownItem-container__total-price">
        {/* Rs {totalPrice} */}
      </div>
    </div>
  );
};

export default ListDownOrderItems;
