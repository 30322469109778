import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ListDownItem from '../ListDownItem/ListDownItem'
import ListDownOrderItems from '../ListDownOrderItems/ListDownOrderItems';
import OrderStatusIndicator from '../OrderStatusIndicator/OrderStatusIndicator';
import "./SpecificOrderSubContent.scss"
import { backEndIP, clearskyIP } from '../configuration'
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const SpecificOrderSubContent = ({ userCookie, userPermission, currentOrderNo, SetLoadingVisibility, handleNotifyAlert }) => {
  const urllocation = useLocation();


  const [OrderListItems, setOrderListItems] = useState({ cart: [], current_status: [1] })
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate();
  const [userData, setUserData] = useState({})
  useEffect(() => {
    // console.log("currentOrderNo",currentOrderNo);
    if (userCookie.token === "" || !userCookie.token) {
      navigate("/");
      return;
    }
    SetLoadingVisibility(true);
    axios({
      url: backEndIP + "/order/get-order",
      method: "POST",
      headers: {
        authorization: userCookie.token,
      },
      data: { order_no: currentOrderNo }
    })
      .then((resp) => {
        SetLoadingVisibility(false);
        //console.log(resp.data.data);
        // resp.data.data.current_status[0]=5;

        setOrderListItems(resp.data.data)
        setUserData(resp.data.userData)
      })
      .catch((err) => {
        SetLoadingVisibility(false);
        if (err.response.data.message) window.alert(err.response.data.message);
        else window.alert("some error occurered");
        //console.log(err);
      });

  }, [refresh])

  const deliveryConfirmation = async () => {
    SetLoadingVisibility(true)
    await axios({
      url: backEndIP + "/order/update_order_status",
      method: "PUT",
      headers: {
        authorization: userCookie.token,
      },
      data: {
        order_id: OrderListItems._id,
        statusNumber: 8,
        statusName: "Delivered"
      }
    }).then((resp) => {
      SetLoadingVisibility(false)
      // console.log(resp);
      setOrderListItems(resp.data.data)
    }).catch((err) => {
      //console.log(err);
      SetLoadingVisibility(false);
      handleNotifyAlert(
        "notify",
        `${err.response.data.message}`,
        "failed",
        () => { }
      );
    })
  }

  const handleOrderDeliveryConfirm = () => {
    // setShowPopup(false);
    //SetLoadingVisibility(false)
    handleNotifyAlert("alert", `Are you sure! You want to confirm the delivery Order with Order ID. ${OrderListItems.order_no}?`, "alert", () => { deliveryConfirmation() })

    //console.log(cancelOrderConfirm)
  }

  const calculateTotalWeight = () => {
    if (OrderListItems.length) {
      let totalWeight = 0;
      OrderListItems.forEach(item => {
        totalWeight += item.quantity * item.weight;
      });
      return totalWeight
    }
    return ""
  }
  const calculateTotalPrice = () => {
    if (OrderListItems.length) {
      let totalWeight = 0;
      OrderListItems.forEach(item => {
        totalWeight += item.quantity * item.price;
      });
      return totalWeight
    }
    return ""
  }


  const handleViewMyFlightClick = () => {
    window.open(clearskyIP + `/user/view-my-flight/?token=${userCookie.token}&order_no=${currentOrderNo}`, "_blank")
  }


  //console.log("userPermission", userPermission, "userData", userData)
  // src={`${backEndIP}/refreshIcon-480.svg`}
  return (
    <div className='SpecificOrderSubContent-container'>
      <div className='SpecificOrderSubContent-container__ItemContainer'>
        <div className='SpecificOrderSubContent-container__title'>
          Order ID:{currentOrderNo}</div>
        {OrderListItems.current_status.number === 9 ? null : <div className='SpecificOrderSubContent-container__firstHead'>
          {userPermission === "Granted" ? <div className='SpecificOrderSubContent-container__customerDataContainer'>
            <div className='SpecificOrderSubContent-container__customerDataContainer__label'>Customer Details:</div>
            <div className='SpecificOrderSubContent-container__customerDataContainer__value'>
              <div className='SpecificOrderSubContent-container__customerDataContainer__value__UserDataContainer'>
                <div className='SpecificOrderSubContent-container__customerDataContainer__value__UserDataContainer__label'>Name : </div>{userData.userName}</div>
              <div className='SpecificOrderSubContent-container__customerDataContainer__value__UserDataContainer'>
                <div className='SpecificOrderSubContent-container__customerDataContainer__value__UserDataContainer__label'>Email : </div>{userData.useremail}</div>
              <div className='SpecificOrderSubContent-container__customerDataContainer__value__UserDataContainer'>
                <div className='SpecificOrderSubContent-container__customerDataContainer__value__UserDataContainer__label'>Phone No : </div>{userData.phone_no}</div>
            </div></div> : null}
          <img className='SpecificOrderSubContent-container__firstHead__refresh' src="../refreshIcon.svg" alt="refresh"
            onClick={() => { setRefresh(prev => { return !prev }) }} /></div>}

      </div>
      <div className='SpecificOrderSubContent-container__ItemContainer'>
        <div className='SpecificOrderSubContent-container__totalItems'>
          <div className='SpecificOrderSubContent-container__totalItems__label'> Total Items :
          </div>{OrderListItems.cart.length}
        </div>
        <div className='SpecificOrderSubContent-container__placedDate'>
          <div className='SpecificOrderSubContent-container__placedDate__label'>Order Date : </div>
          {dayjs(OrderListItems.placed).format("lll").toString()}</div>
        <div className='SpecificOrderSubContent-container__placedDate'>
          <div className='SpecificOrderSubContent-container__placedDate__label'>Order Type : </div>
          {OrderListItems.delivery_type}</div>
        {OrderListItems.cancelled === null ? <>{OrderListItems.delivered === null ? <div className='SpecificOrderSubContent-container__placedDate'>
          <div className='SpecificOrderSubContent-container__placedDate__label'>Expected Delivery : </div>
          {dayjs(OrderListItems.delivery_date).format("lll").toString()}</div> : <div className='SpecificOrderSubContent-container__placedDate'>
          <div className='SpecificOrderSubContent-container__placedDate__label'>Delivered On : </div>
          {dayjs(OrderListItems.delivered).format("lll").toString()}</div>}</> :
          <div className='SpecificOrderSubContent-container__placedDate'>
            <div className='SpecificOrderSubContent-container__placedDate__label'>Cancelled On: </div>
            {dayjs(OrderListItems.cancelled).format("lll").toString()}</div>}
      </div>
      <div className='SpecificOrderSubContent-container__statusBar'>
        {OrderListItems.current_status.number === 9 ?
          <div>
            <div className='SpecificOrderSubContent-container__statusBar__canceled'>Cancelled</div>
            {OrderListItems.cancellationReason &&
              <div className='SpecificOrderSubContent-container__statusBar__canceledReason'>
                Cancellation Reason :<div className='SpecificOrderSubContent-container__statusBar__canceledReason__value' >{OrderListItems.cancellationReason}</div></div>}
          </div> :
          <OrderStatusIndicator status={OrderListItems.current_status} refresh={refresh} />}

      </div>

      <div className='SpecificOrderSubContent-container__subContainer'>
        <div className='ListDownItems-container' >
          <div className='ListDownItems-container__Sr-No'>S.No</div>
          <div className='ListDownItems-container__name'>Name</div>
          <div className='ListDownItems-container__unit-price'></div>
          <div className='ListDownItems-container__weight'>Weight</div>
          <div className='ListDownItems-container__Volumetricweight'>Volumetric Weight</div>
          <div className='ListDownItems-container__quantity'>Quantity</div>
          <div className='ListDownItems-container__totalWeight'>Total Weight</div>
          <div className='ListDownItems-container__total-price'></div>
        </div>
        <div className='SpecificOrderSubContent-container__subContainer__listDown'>
          <div>

            {OrderListItems.cart.map((item, i) => { return <ListDownOrderItems item={item} index={i} key={`${item.name} ${i}`} /> })}
          </div>
        </div>
        <div className='ListDownItems-container' >
          <div className='ListDownItems-container__Sr-No'>{OrderListItems.sent_out_delivery === null ? null : <>{OrderListItems.delivered === null ?
            <button className='ListDownItems-container__Sr-No__cnfrBtn' onClick={handleOrderDeliveryConfirm}>Confirm Delivery</button> : null}</>}
            {OrderListItems.flightId === "" ? null : <>{OrderListItems.current_status.number < 7 ?
              <button className='ListDownItems-container__Sr-No__viewFlightButton'
                onClick={handleViewMyFlightClick}
              >View Flight</button> : null}</>}</div>
          <div className='ListDownItems-container__name'></div>
          <div className='ListDownItems-container__weight'></div>
          <div className='ListDownItems-container__Volumetricweight'></div>
          <div className='ListDownItems-container__quantity'>Cart Weight</div>
          <div className='ListDownItems-container__totalWeight'>{OrderListItems.total_weight}g</div>
          {/* <div className='ListDownItems-container__quantity'>Total Price</div> */}
          {/* <div className='ListDownItems-container__total-price'>{calculateTotalPrice()}Rs</div> */}
        </div>
      </div>
    </div>
  )
}

export default SpecificOrderSubContent