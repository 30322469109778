import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import "./ProductItemCard.scss"
import { backEndIP } from '../configuration'

const CardContainer = styled.div`
    position: relative;
    width: calc(260px * var(--widthmultiplier));
    height: calc(345px * var(--heightmultiplier));
    background-color: var(--secondaryColor);
    box-sizing: border-box;
    border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    // border: 1px solid black;
    font-family: 'General Sans';
    margin:calc(15px * var(--heightmultiplier)) calc(15px * var(--widthmultiplier));
`
const Container = styled.div`
position:absolute;
top:0px;
left:0px;
width: 100%;
height: 100%;
background-color: var(--primaryColor);
box-sizing: border-box;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
padding: calc(30px * var(--heightmultiplier)) calc(30px * var(--widthmultiplier));
border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
z-index:2;
&:hover{
    border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    top: calc(-20px * var(--heightmultiplier));
    // box-shadow: 0px 0px 10px #FD6125;
}
`


const Image = styled.img`
    // width: calc(200px * var(--widthmultiplier));
    width:auto;
    height: calc(125px * var(--heightmultiplier));
    background-color: var(--primaryColor);
    box-sizing: border-box;  
    border-radius: calc(8px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
`
const Name = styled.div`
height:calc(42px * var(--heightmultiplier));
width:100%;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
text-overflow: ellipsis;
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
margin-top: calc(21px * var(--heightmultiplier));
font-size: calc(20px * var(--heightmultiplier));
line-height: calc(20px * var(--heightmultiplier));
align-self:flex-start`

const Price = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: calc(22px * var(--heightmultiplier));
line-height: calc(20px * var(--heightmultiplier));
color: var(--primaryTextColor);
align-self:flex-start;
margin-top: calc(11px * var(--heightmultiplier));
color: "var(--primaryTextColor)";
opacity: 0.4;
`;

const Instock = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: calc(16px * var(--heightmultiplier));
line-height: calc(20px * var(--heightmultiplier));
color: var(--primaryTextColor);
align-self:flex-start;
margin-top: calc(11px * var(--heightmultiplier));
rgba($color: var(--primaryTextColor), $alpha: 0.4);
`;

const Button = styled.button`
width: calc(200px * var(--widthmultiplier));
height: calc(40px * var(--heightmultiplier));
background-color: rgba(253,97,37,0.1);
box-sizing: border-box;
padding:0px calc(38px * var(--widthmultiplier));
border-radius: calc(8px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
border:none;
display:flex;
align-items:center;
justify-content:space-between;
margin-top: calc(23px * var(--heightmultiplier));
color: #FD6125;

&:hover{
    cursor: pointer;
    background-color: rgba(253,97,37);
    color: white;
}
`;
const StockOutButton = styled.button`
width: calc(200px * var(--widthmultiplier));
height: calc(40px * var(--heightmultiplier));
background-color: rgba(253,97,37,0.1);
box-sizing: border-box;
padding:0px calc(38px * var(--widthmultiplier));
border-radius: calc(8px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
border:none;
display:flex;
align-items:center;
justify-content:center;
margin-top: calc(23px * var(--heightmultiplier));
color: #FD6125;

`;
const ButtonStr = styled.div`

height:100%;
width: auto;
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: calc(18px * var(--heightmultiplier));
line-height: calc(24px * var(--heightmultiplier));
// border:1px solid black;
display: flex;
align-items: center;
`;


const ProductItemCard = ({ selectedProduct, SetSelectedProduct, product, userCookie, handleNotifyAlert, SetLoadingVisibility, BagList, setBagList, setCartProductCount }) => {

    const [cartIconColor, setCartIconColor] = useState("cartBag.svg");
    const PdtRef = useRef();
    const handleProductClick = (e) => {
        SetSelectedProduct(product);
        console.log(selectedProduct);

    }



    useEffect(() => {
        if (selectedProduct === product) {
            // console.log(PdtRef.current.style.Client)
            // PdtRef.current.style={: "calc(-20px * var(--heightmultiplier))"}
        }
        else {
            // PdtRef.current.style = { top: "0px" }
        }
    }, [selectedProduct])


    const handleButtonMouseOver = () => {
        setCartIconColor("cartBagWhite.svg");
    }
    const handleButtonMouseOut = () => {
        setCartIconColor("cartBag.svg");
    }

    const handleAddToCartClick = (e) => {
        e.stopPropagation();
        SetLoadingVisibility(true);
        axios({
            url: backEndIP + "/cart-add/add-item-cart",
            method: "POST",
            headers: {
                authorization: userCookie.token
            },
            data: { id: product._id, quantity: 1 }
        }).then((resp) => {
            //console.log(resp.data.data);
            SetLoadingVisibility(false);
            setBagList(resp.data.data.cart)
            handleNotifyAlert("notify", `${product.name} : ${1} unit added successfully`, "success", () => { })

        }).catch((err) => {
           // console.log(err);
            SetLoadingVisibility(false);
            if (err.response.message)
                window.alert(err.response.message)
            else window.alert("some error occurered")
            //console.log(err);
        })
    }
    return (
        <CardContainer style={{boxShadow:selectedProduct===product?"0px 0px 15px #FD6125":"none", scale:selectedProduct===product?'1.06':'1'}}>
            <div className='productCardContainer' onClick={handleProductClick} ref={PdtRef}  >
                <Image src={`${backEndIP}/${product.image_name}`} alt='pdt'></Image>
                <Name>{product.name}</Name>
                {/* <Price>Rs {parseInt(product.price).toFixed(2)}</Price> */}
                <Instock>Instock: {parseInt(product.available_quantity)} units</Instock>
                {product.available_quantity > 0 && <Button onClick={handleAddToCartClick}
                    onMouseOver={handleButtonMouseOver}
                    onMouseOut={handleButtonMouseOut}>
                    <ButtonStr>
                        Add to Bag
                    </ButtonStr>
                    <img style={{
                        width: "calc(18px * var(--widthmultiplier))",
                        height: "calc(20px * var(--heightmultiplier))"
                    }}
                        src={cartIconColor} alt='bagIcon'></img>
                </Button>}
                {product.available_quantity < 1 && <StockOutButton
                    style={{ backgroundColor: "lightgrey", color: "white" }}>
                    <ButtonStr>Out of Stock</ButtonStr>
                </StockOutButton>}
            </div>
        </CardContainer>
    )
}

export default ProductItemCard