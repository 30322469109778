import React, { useState } from "react";
import CategoryAdd from "../Category/CategoryAdd";
import InventoryForm from "../InventoryForm/inventory";
import InventoryTabel from "../InventoryTable/InventoryTabel";
import "./manageInventory.scss";
import OrdersTabel from "../ViewAllOrders/viewAllOrders";

const ManageInventory = ({
  SetCategories,
  userCookie,
  SetLoadingVisibility,
  handleNotifyAlert,
}) => {
  const [select, setSelect] = useState("");
  
  // const handleSelect = ()=>{
  //   setSelect("Add Inventory");
  //   console.log(select);
  // }
  //console.log(select);
  return (
    <div className="InventoryManagementContainer">
      <div className="ManageInventory">
        <div className="ManageInventory__title">Manage Inventory</div>
        <div className="ManageInventory__header">
          <button
            className="ManageInventory__header__links"
            onClick={() => setSelect("Add Inventory")}
          >
            Add Inventory
          </button>
          <button
            className="ManageInventory__header__link1"
            onClick={() => setSelect("Inventory List")}
          >
            Inventory List
          </button>
          <button
            className="ManageInventory__header__link1"
            onClick={() => setSelect("Order List")}
          >
            Order List
          </button>
          <button className="ManageInventory__header__link"
          onClick={() => setSelect("Add Categories")}>
            Add Categories
          </button>
        </div>
      </div>
      <div className="ManageInventory__block">
        {select === "" && (
          <InventoryForm
            userCookie={userCookie}
            SetLoadingVisibility={SetLoadingVisibility}
            handleNotifyAlert={handleNotifyAlert}
          />
        )}
        {select === "Add Inventory" && (
          <InventoryForm
            userCookie={userCookie}
            SetLoadingVisibility={SetLoadingVisibility}
            handleNotifyAlert={handleNotifyAlert}
          />
        )}
        {select === "Inventory List" && (
          <InventoryTabel
            userCookie={userCookie}
            SetLoadingVisibility={SetLoadingVisibility}
            handleNotifyAlert={handleNotifyAlert}
          />
        )}
         {select === "Order List" && (
          <OrdersTabel
            userCookie={userCookie}
            SetLoadingVisibility={SetLoadingVisibility}
            handleNotifyAlert={handleNotifyAlert}
            
          />
        )}
        {select === "Add Categories" && (
          <CategoryAdd
            SetCategories ={SetCategories}
            userCookie={userCookie}
            SetLoadingVisibility={SetLoadingVisibility}
            handleNotifyAlert={handleNotifyAlert}
          />
        )}
      </div>
    </div>
  );
};

export default ManageInventory;
