import React, { useRef } from "react";
import "./InventoryUpdatePopup.scss";

function InventoryUpdatePopup({ setOpenModal, messageToDisplay }) {
  const modalRef = useRef();

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setOpenModal(false);
    }
  };


  return (
    <div onClick={closeModal} ref={modalRef} className="InventoryUpdatePopup-modalBackground">
      <div className="InventoryUpdatePopup-modalContainer" >
        {/* <div className="custom-alert-box-name">Alert</div> */}
        <div className="InventoryUpdatePopup-modalContainer__box-msg">{messageToDisplay.message}</div>
        {/* <div className="InventoryUpdatePopup-modalContainer__box-button-container">
          <div onClick={() => { modalRef.current.click(); }} id="cancelBtn">Cancel</div>
          <div onClick={() => { messageToDisplay.func(); }} id="OkayBtn">OK</div>
        </div> */}
      </div>
    </div>
  );
}

export default InventoryUpdatePopup;
