import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "./HomePage.scss";
import Spinner from "react-bootstrap/Spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomAlertBox from "./CustomAlert/CustomAlertBox";
import { useLocation, useNavigate } from "react-router-dom";
import InfoIndicatorBox from "./InfoIndicatorBox/InfoIndicatorBox";
import io from "socket.io-client";
import CategoryListItem from "./CategoryListItem/CategoryListItem";
import InventoryShowSubContent from "./InventoryShowSubContent/InventoryShowSubContent";
import styled from "styled-components";
import UserDropDown from "./UserDropDown/UserDropDown";
import OrderListSubContent from "./OrdersListSubContent/OrdersListSubContent";
import BagListSubContent from "./BagListSubContent/BagListSubContent";
import OrdersListSubContent from "./OrdersListSubContent/OrdersListSubContent";
import SpecificOrderSubContent from "./SpecificOrderSubContent/SpecificOrderSubContent";
import InventoryAdd from "./InventoryAddFrom/InventoryAddFormPage";
import CustomNotification from "./CustomNotification/CustomNotification";
import LoadingScreen from "./LoadingScreen/LoadingScreen";
import InventoryForm from "./InventoryForm/inventory";
import { backEndIP } from "./configuration";
import axios from "axios";
import InputForm from "./CustomInputField/inputComponent";
import ManageInventory from "./ManageInventory/ManageInventory";
import { BrowserView, isBrowser, isDesktop, isMobile, MobileView } from "react-device-detect";
import MyProfile from "./UserProfile/MyProfile";
import UserHelpManual from "./HelpManual/HelpManual";
import HelpManual from "./HelpManual/HelpManual";

// const socket = io("https://ws5002.dineshborse.in", { transports: ["websocket", "polling"] });

const BagButtonDiv = styled.button`
  width: calc(101px * var(--widthmultiplier));
  height: calc(45px * var(--heightmultiplier));
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primaryColor);
  border-radius: calc(
    10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2
  );
  border:none;
  padding: calc(0px * var(--heightmultiplier))
    calc(18px * var(--widthmultiplier));
  &:hover {
    cursor: pointer;
  }
`;

const HomePage = ({ userCookie, setuserCookie, removeCookie, themeGlobal, userName, navSubContent }) => {
  const navigate = useNavigate();
  let urllocation = useLocation();
  let orderNo = 0;
  if (navSubContent[1] === "SpecficOrder") {
    orderNo = urllocation.pathname.split("/")
    orderNo = parseInt(orderNo[orderNo.length - 1])
  }
  const [BagList, setBagList] = useState([]);
  const [cartQuantityUpdated, setCartQuantityUpdated] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const [userIconClicked, setUserIconClicked] = useState(false);
  const [userPermission, setUserPermission] = useState({})
  const [myLocation, setMyLocation] = useState({})
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubContent, setSelectedSubContent] = useState(navSubContent[1]);
  const [currentOrderNo, setCurrentOrderNo] = useState(orderNo);
  const [notificationVisibility, setNotificationVisibility] = useState(false);
  const [alertVisibility, setAlertVisibility] = useState(false);
  const [userHubLocation,setUserHubLocation] = useState("")
  const [messageToDisplay, setMessageToDisplay] = useState({
    type: "notify",
    message: "msg",
    msgtype: "success",
    func: () => { },
  });
  const [loadingVisibility, SetLoadingVisibility] = useState(false);
  const [categories, setCategories] = useState([]);
  //console.log("SetUserCookie", setuserCookie)
  // console.log("UserCookie", userCookie)

  useEffect(() => {
    if (userCookie.token === "" || !userCookie.token) {
      navigate("/");
      return;
    } 
    if (userCookie.token) {
      axios({
        url: backEndIP + "/user/verifytoken",
        // url: backEndIP + "/user/login",
        method: "GET",
        headers: {
          authorization: userCookie.token
        },
      }).then(async (loginData) => {
        await setuserCookie("token", loginData.data.token, { path: "/" })
        await setuserCookie("username", loginData.data.userName, { path: "/" })
        await setuserCookie("useremail", loginData.data.userEmail, { path: "/" })
        setUserPermission(loginData.data.permissionStatus)
        setMyLocation(loginData.data.userLocation)
        setUserHubLocation(loginData.data.hubLocation)
        // console.log(loginData.data.hubLocation)

      }).catch((err) => {
        // window.alert("login again")
        //console.log(err);
        removeCookie("token");
        removeCookie("username");
        removeCookie("useremail");
      })

    }
    else {
      navigate("/")
    }

  }, [])

  React.useEffect(() => {
    //console.log(urllocation, navSubContent[0])
    // if(urllocation.pathname!==navSubContent[0]){
    // navigate(urllocation.pathname)

    setSelectedSubContent(navSubContent[1])
    // }
  }, [urllocation.pathname]);

  useEffect(() => {
    if (userCookie.token === "" || !userCookie.token) {
      navigate("/");
      return;
    } 
    if (userCookie.token) {
      SetLoadingVisibility(true);
      axios({
        url: backEndIP + "/cart-add/get-cart-items",
        method: "GET",
        headers: {
          authorization: userCookie.token,
        },
        // data: { id: product._id , quantity:1 }
      })
        .then((resp) => {
          SetLoadingVisibility(false);
          //console.log(resp.data);
          setBagList(resp.data.cart);
          setCartQuantityUpdated(true);
        })
        .catch((err) => {
          SetLoadingVisibility(false);
          if (err.response.data.message) window.alert(err.response.data.message);
          else window.alert("some error occurered");
          // console.log(err);
        });
    }
    if (userCookie.token) {
      SetLoadingVisibility(true);
      axios({
        url: backEndIP + "/order/get-all-location",
        method: "GET",
        headers: {
          authorization: userCookie.token,
        },
        // data: { id: product._id , quantity:1 }
      })
        .then((resp) => {
          SetLoadingVisibility(false);
          // console.log(resp.data.data);
          setAllLocations(resp.data.data);
          // SetDestLocation(allLocations[1]);
        })
        .catch((err) => {
          SetLoadingVisibility(false);
          if (err.response.data.message) window.alert(err.response.data.message);
          else window.alert("some error occurred");
          //console.log(err);
        });
      // setBagList(sampleBagList)
    }
  }, []);

  useEffect(() => {
    //  console.log(userCookie.token)
    if (userCookie.token === "" || !userCookie.token) {
      navigate("/");
    } else {
      axios({
        url: backEndIP + "/category/get_category",
        method: "GET",
        headers: {
          authorization: userCookie.token,
        },
      }).then((data) => {
        //console.log(data.data.data[0]);
        setCategories(data.data.data);
        // setCategory(data.data.data[0].name)
      });
    }

  }, [userCookie.token]);


  // useEffect(() => {
  //     socket.on('connect', () => {
  //         setIsSocketConnected(true);
  //         socket.emit("upgrade")
  //     });

  //     socket.on('disconnect', () => {
  //         setIsSocketConnected(false);
  //     });

  //     socket.on('pong', () => {
  //         setLastPong(new Date().toISOString());
  //     });

  //     // socket.on()

  //     return () => {
  //         socket.off('connect');
  //         socket.off('disconnect');
  //         socket.off('pong');
  //     };
  // }, []);

  const navlinks = {
    "Orders": "/all-orders",
    "": "/all-categories",
    "BagList": "/baglist",
    "SpecficOrder": `/order/${currentOrderNo}`,
    "Manage Inventory": "/manage-inventory",
    "My Profile": "/myProfile",
    "Help": "/help"
  }

  useEffect(() => {
    if (userCookie.token === "" || !userCookie.token) {
      navigate("/");
      return;
    } 
    navigate(navlinks[selectedSubContent])
  }, [selectedSubContent])

  const handleSubContentSelect = (optionName) => {
    if (optionName !== "Logout" && optionName !== "") {
      setSelectedCategory("");
      setSelectedSubContent(optionName);
      // navigate(navlinks[optionName])

    }
    console.log(optionName);
    setUserIconClicked(false);

    if (optionName === "Logout") {
      setuserCookie("token", "", { path: "/" });
    }
  };
  // useEffect(() => {
  //   setSelectedSubContent(selectedCategory);
  //   // setUserIconClicked(false);
  // }, [selectedCategory]);

  const handleUpdateAlertNottifyMsg = (type, message, msgtype, func) => {
    setMessageToDisplay({
      type: type,
      message: message,
      msgtype: msgtype,
      func: () => {
        SetLoadingVisibility(false);
        func();
      },
    });
    if (type === "notify") {
      setNotificationVisibility(true);
    } else if (type === "alert") {
      setNotificationVisibility(true);
    }
  };
  return (
    <>
      {<div className="home-page-container">
        <div className="home-sidebar">
          <div className="home-sidebar__logodiv">
            <img
              className="home-sidebar__logodiv__logoimg"
              src={backEndIP + "/" + themeGlobal.nameLogo}
              alt="logo"
            />
          </div>
          <div className="home-sidebar__logodiv-border"></div>
          <div className="home-sidebar__Categories-container">
            <div
              className="home-sidebar__Categories-container__Title"
              onClick={() => {
                setSelectedCategory("");
                setSelectedSubContent("");
              }}
            >
              Categories
            </div>
            {categories.map((cat) => {
              return (
                <CategoryListItem
                  categoryName={cat.name}
                  key={`category${cat.name}`}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  setSelectedSubContent={setSelectedSubContent}
                />
              );
            })}
          </div>
          {/* <img className='home-main-map__search-container__phc-logo'
                    style={{
                        height: "calc(200px * var(--heightmultiplier))",
                        width: "calc(200px * var(--heightmultiplier))",
                        marginTop: "calc(600px * var(--heightmultiplier))"
                    }}
                    src='PHC-logo.svg' alt="phc logo" /> */}
        </div>
        <div className="home-main-map">
          <div className="home-main-map__topInfobar">
            {/* <div className='home-main-map__search-container'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                        >
                            <path fillRule="evenodd" clipRule="evenodd" d="M5 11C5 7.691 7.691 5 11 5C14.309 5 17 7.691 17 11C17 14.309 14.309 17 11 17C7.691 17 5 14.309 5 11ZM20.707 19.293L17.312 15.897C18.365 14.543 19 12.846 19 11C19 6.589 15.411 3 11 3C6.589 3 3 6.589 3 11C3 15.411 6.589 19 11 19C12.846 19 14.543 18.365 15.897 17.312L19.293 20.707C19.488 20.902 19.744 21 20 21C20.256 21 20.512 20.902 20.707 20.707C21.098 20.316 21.098 19.684 20.707 19.293Z"
                                fill="var(--primaryTextColor)" />
                        </svg>
                        <input className='home-main-map__search-container__search'
                            type={"text"} placeholder={"Search products here"}></input>
                    </div> */}
            <img
              className="home-main-map__topInfobar__phc-logo"
              // style={{
              //     height: "calc(150px * var(--heightmultiplier))",
              //     width: "calc(150px * var(--heightmultiplier))",
              //     border: "1px solid black"
              // }}
              src={backEndIP + "/PHC-logo.svg"}
              alt="phc logo"
            />
            {/* <div
            className="home-main-map__topInfobar__phc-logo"
            
          >LOGO</div> */}

            <div className="home-main-map__topInfobar__user">
              <BagButtonDiv
                onClick={() => {
                  setSelectedSubContent("BagList");
                }}
              >
                <div className="home-main-map__topInfobar__user__bagStr">
                  Bag
                </div>
                <div className="home-main-map__topInfobar__user__bagImg">
                  <img
                    className="home-main-map__topInfobar__user__bagImg__img"
                    src={backEndIP + "/cartBag.svg"}
                    alt="bagIcon"
                  ></img>

                  {BagList.length === 0 ? null : <div className="home-main-map__topInfobar__user__bagImg__productCount">{BagList.length}</div>}
                </div> </BagButtonDiv>
              <div
                className="home-main-map__topInfobar__user__proImg"
                onClick={() => {
                  setUserIconClicked(true);
                }}
              >
                {userName ? userName[0].toUpperCase() : ""}
              </div>
              <svg onClick={() => {
                setUserIconClicked(true);
              }}
                className="home-main-map__topInfobar__user__img"
                width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M7.00016 8C6.64177 8 6.28338 7.86934 6.01039 7.60935L0.410539 2.27624C-0.136846 1.75493 -0.136846 0.912295 0.410539 0.390984C0.957924 -0.130328 1.8427 -0.130328 2.39008 0.390984L7.01696 4.79747L11.627 0.557643C12.1856 0.0469981 13.069 0.0616641 13.6066 0.590975C14.1442 1.12029 14.1288 1.96558 13.573 2.47623L7.97313 7.62535C7.70014 7.87601 7.35015 8 7.00016 8Z"
                  fill={themeGlobal.themeColor["--primaryColor"] === "white" ? "black" : "white"} />
              </svg>

            </div>
          </div>
          <div className="home-main-map__content">
            {selectedSubContent === "" && (
              <InventoryShowSubContent
                SetLoadingVisibility={SetLoadingVisibility}
                selectedCategory={selectedCategory}
                userCookie={userCookie}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
                BagList={BagList}
                setBagList={setBagList}
              />
            )}
            {selectedSubContent === "Orders" && (
              <OrderListSubContent
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
                setContentOption={handleSubContentSelect}
                setOrderNo={setCurrentOrderNo}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
              />
            )}
            {selectedSubContent === "SpecficOrder" && (
              <SpecificOrderSubContent
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
                userPermission={userPermission}
                setContentOption={handleSubContentSelect}
                currentOrderNo={currentOrderNo}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
              />
            )}
            {selectedSubContent === "BagList" && (
              <BagListSubContent
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
                userPermission={userPermission}
                userHubLocation ={userHubLocation}
                myLocation={myLocation}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
                BagList={BagList}
                setBagList={setBagList}
                allLocations={allLocations}
                setCartQuantityUpdated={setCartQuantityUpdated}
                cartQuantityUpdated={cartQuantityUpdated}
              />
            )}
            {/* {selectedSubContent === "Add Inventory" && (
              <InventoryForm
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
              />
            )} */}
            {selectedSubContent === "Manage Inventory" && (
              <ManageInventory
                SetCategories={setCategories}
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
              />
            )}
            {selectedSubContent === "My Profile" && (
              <MyProfile
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
                setContentOption={handleSubContentSelect}
                currentOrderNo={currentOrderNo}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
              />
            )}
            {selectedSubContent === "Help" && (
             <HelpManual
                SetCategories={setCategories}
                userPermission={userPermission}
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
              />
            )}
          </div>
          <div className="home-main-map__footer-container">
            <div className="home-main-map__footer-container__copyright">
              <img
                className="home-main-map__footer-container__copyright__company-logo"
                src={`${backEndIP}/${themeGlobal.EagleLogo}`}
              ></img>
              <div className="home-main-map__footer-container__copyright__title">
                Copyright 2023. TechEagle Innovations Pvt.Ltd.
              </div>
            </div>
          </div>
        </div>
        {userIconClicked && (
          <UserDropDown
            userPermission={userPermission}
            setOpenModal={setUserIconClicked}
            setContentOption={handleSubContentSelect}
          />
        )}
        {notificationVisibility && (
          <CustomNotification
            setOpenModal={setNotificationVisibility}
            messageToDisplay={messageToDisplay}
          />
        )}
        {loadingVisibility && <LoadingScreen />}
      </div>}

      {/* {isMobile && <div className="m__home-page-container">
        <div className="m__home-page-container__topbar">
          <img
            className="m__home-page-container__topbar__logoimg"
            src={themeGlobal.nameLogo}
            alt="logo"
          />
        </div>
        <div className="home-main-map">
          <div className="home-main-map__topInfobar">
            <div className="home-main-map__topInfobar__user">
              <BagButtonDiv
                onClick={() => {
                  setSelectedSubContent("BagList");
                }}
              >
                <div className="home-main-map__topInfobar__user__bagStr">
                  Mobile Bag
                </div>
                <img
                  style={{
                    width: "calc(18px * var(--widthmultiplier))",
                    height: "calc(20px * var(--heightmultiplier))",
                  }}
                  src={"cartBag.svg"}
                  alt="bagIcon"
                ></img>
              </BagButtonDiv>
              <div
                className="home-main-map__topInfobar__user__proImg"
                onClick={() => {
                  setUserIconClicked(true);
                }}
              >
                {userName ? userName[0].toUpperCase() : ""}
              </div>
              <img
                className="home-main-map__topInfobar__user__img"
                src="userDownArrow.svg"
              ></img>
            </div>
          </div>
          <div className="home-main-map__content">
            {selectedSubContent === "" && (
              <>
                <div className="home-sidebar__Categories-container">
                  <div
                    className="home-sidebar__Categories-container__Title"
                    onClick={() => {
                      setSelectedCategory("");
                      setSelectedSubContent("");
                    }}
                  >
                    Categories
                  </div>
                  {categories.map((cat) => {
                    return (
                      <CategoryListItem
                        categoryName={cat.name}
                        key={`category${cat.name}`}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        setSelectedSubContent={setSelectedSubContent}
                      />
                    );
                  })}
                </div>
                <InventoryShowSubContent
                  SetLoadingVisibility={SetLoadingVisibility}
                  selectedCategory={selectedCategory}
                  userCookie={userCookie}
                  handleNotifyAlert={handleUpdateAlertNottifyMsg}
                />
              </>
            )}
            {selectedSubContent === "Orders" && (
              <OrderListSubContent
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
                setContentOption={handleSubContentSelect}
                setOrderNo={setCurrentOrderNo}
              />
            )}
            {selectedSubContent === "SpecficOrder" && (
              <SpecificOrderSubContent
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
                setContentOption={handleSubContentSelect}
                currentOrderNo={currentOrderNo}
              />
            )}
            {selectedSubContent === "BagList" && (
              <BagListSubContent
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
              />
            )}
            {selectedSubContent === "Manage Inventory" && (
              <ManageInventory
               SetCategories ={setCategories}
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
              />
            )}
          </div>
          <div className="home-main-map__footer-container">
            <div className="home-main-map__footer-container__copyright">
              <img
                className="home-main-map__footer-container__copyright__company-logo"
                src={themeGlobal.EagleLogo}
              ></img>
              <div className="home-main-map__footer-container__copyright__title">
                Copyright 2022. TechEagle Innovations Pvt.Ltd.
              </div>
            </div>
          </div>
        </div>
        {userIconClicked && (
          <UserDropDown
            setOpenModal={setUserIconClicked}
            setContentOption={handleSubContentSelect}
          />
        )}
        {notificationVisibility && (
          <CustomNotification
            setOpenModal={setNotificationVisibility}
            messageToDisplay={messageToDisplay}
          />
        )}
        {loadingVisibility && <LoadingScreen />}
      </div>} */}
    </>

  );
};

export default HomePage;
