import axios from "axios";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { backEndIP } from "../configuration";
import "./InventoryUpdateForm.scss"

const UpdateForm = ({ props, setShowPopup, SetLoadingVisibility, userCookie, handleNotifyAlert, setListItems }) => {
  const fileInputElement = useRef();
  const inputBase64data = useRef();
  const fileNameTextBox = useRef();
  const [currentImg, setCurrentImg] = useState("");
  const [calculationKey, setCalculationKey] = useState("")
  const [newQuty, setNewQuty] = useState(0)

  //const [currentImage, setCurrentImage] = useState()
  const [updateData, setUpdateData] = useState({
    _id: props._id,
    name: props.name,
    filedata: props.image_name,
    description: props.description,
    available_quantity: props.available_quantity,
    weight: props.weight,
    price: props.price,
    category: props.category,
  });
  const handleCancel = () => {
    setShowPopup(false);
  }
  // console.log(updateData._id)
  const handleFileOnChange = () => {
    const reader = new FileReader();
    // setItem_image(e.target.value);
    if (fileNameTextBox.current.value === "") {
    }
    if (fileInputElement.current.files.length) {
      fileNameTextBox.current.value = fileInputElement.current.files[0].name;
      // console.log(fileInputElement.current.files);
      reader.onload = () => {
        //console.log(reader.result);
        inputBase64data.current.value = reader.result;
        setCurrentImg(reader.result);
        setUpdateData({ ...updateData, filedata: reader.result });
      };
      reader.readAsDataURL(fileInputElement.current.files[0]);
    } else {
      console.log("No File Selected");
    }
  };
  //   console.log("Image", currentImg)
  const handleUpdateButton = (_id) => {
    console.log("Item Id",_id)
    SetLoadingVisibility(true)
    axios({
      url: backEndIP + `/inventory/update-inventory/${_id}`,
      method: "PUT",
      headers: {
        authorization: userCookie.token,
      },
      data: {
        ...updateData,
      },
      //
    }).then((data) => {
      SetLoadingVisibility(false)
      setShowPopup(false);
      handleNotifyAlert(
        "notify",
        `Item Updated successfully`,
        "success",
        () => { }
      );
      // console.log(data.data.data)
      //SetLoadingVisibility(false)
      setListItems(data.data.data.sort((a, b) => (a.serial_no > b.serial_no ? 1 : -1)))
      //window.location.reload(false);
    })
      .catch((err) => {
        //console.log(err);
        SetLoadingVisibility(false);
        setShowPopup(false);
        handleNotifyAlert(
          "notify",
          `${err.response.data.message}`,
          "failed",
          () => { }
        );
      });
  }
 // console.log(calculationKey)
  const updateItemQuantity = (_id, key) => {
   // console.log(_id, key)
    SetLoadingVisibility(true)
    
    axios({
      url: backEndIP + `/inventory/update-quantity/${_id}`,
      method: "PUT",
      headers: {
        authorization: userCookie.token,
      },
      data: {
        key: key,
        newQuantity: newQuty,
      },
      //
    }).then((Respdata) => {
      console.log(Respdata.data)
      SetLoadingVisibility(false)
      setUpdateData({ ...updateData, ["available_quantity"]: Respdata.data.currentItem });
      setCalculationKey("")
      setNewQuty(0)
      setListItems(Respdata.data.data.sort((a, b) => (a.serial_no > b.serial_no ? 1 : -1)))
      //window.location.reload(false);

    })
      .catch((err) => {
        //console.log(err);
        SetLoadingVisibility(false);
        //setShowPopup(false);
        handleNotifyAlert(
          "notify",
          `${err.response.data.message}`,
          "failed",
          () => { }
        );
      });

  }

  const handelQuantityUpdate = (_id, key) => {
    if (newQuty <= 0) {
      handleNotifyAlert(
        "notify",
        `Please Add some Quantity`,
        "failed",
        () => { }
      );
      setCalculationKey("")
    } else {
      if (key === "Sub") {
        if (newQuty > updateData.available_quantity) {
          handleNotifyAlert(
            "alert",
            `You can not remove item more than available items quantity`,
            "failed",
            () => { }
          );
          setCalculationKey("")
        } else {
          //console.log(_id, key)
          // setCalculationKey(key)
          handleNotifyAlert(
            "alert",
            `Are You Sure! You want to remove the quantity`,
            "alert",
            () => { updateItemQuantity(updateData._id, key) }
          );
        }
      } else if(key === "Add") {
        //console.log(_id, key)
        //setCalculationKey(key)
        handleNotifyAlert(
          "alert",
          `Are You Sure! You want to Add the quantity`,
          "alert",
          () => { updateItemQuantity(updateData._id, key) }
        );
      }
    }

  }

  const handleBrowseClick = () => {
    fileInputElement.current.click();
  };
  const handleUpdateInput = (e, id) => {
    if (id === "weight" || id === "price" || id === "available_quantity") {
      setUpdateData({ ...updateData, [id]: parseInt(e.target.value) });
    } else {
      //setCategory({category, [id]: e.target.value})
      setUpdateData({ ...updateData, [id]: e.target.value });
    }
  }

  return (
    <div className="UpdateFormContainer">
      <div className="UpdateFormContainer__heading">Update Product</div>
      <div className="UpdateFormContainer__updateContainer">
        <div className="UpdateFormContainer__updateContainer__img-container">
          {/* <div className="UpdateFormContainer__container__img-container">
                    
                </div> */}
          <img src={currentImg.length ? currentImg : (backEndIP + "/" + updateData.filedata)} alt="item Image" className="UpdateFormContainer__updateContainer__img-container__image" />
          <input ref={fileInputElement} onChange={handleFileOnChange} type="file" className="UpdateFormContainer__updateContainer__img-container__hidden" />
          <input
            ref={inputBase64data}
            type="text"
            name="filedata"
            readOnly="readOnly"
            className="UpdateFormContainer__updateContainer__img-container__hidden"
          ></input>
          <input
            ref={fileNameTextBox}
            type="text"
            className="UpdateFormContainer__updateContainer__img-container__hidden"
            readOnly="readOnly"
            placeholder="No File Selected"
          ></input>
          <button className="UpdateFormContainer__updateContainer__img-container__buttonImage" onClick={handleBrowseClick}>
            <span className="material-symbols-outlined">
              edit
            </span></button>
        </div>
        <div>
          {calculationKey === "" ? <div className="UpdateFormContainer__updateContainer__subContainer">
            <div className="UpdateFormContainer__updateContainer__lable">Quantity</div>
            <div className="UpdateFormContainer__updateContainer__colon">:</div>
            <input readOnly type="number"
              value={updateData.available_quantity}
              className="UpdateFormContainer__updateContainer__value" /></div> : <>{calculationKey === "Add" ?
                <div className="UpdateFormContainer__updateContainer__subContainer">
                  <div className="UpdateFormContainer__updateContainer__lable">Quantity</div>
                  <div className="UpdateFormContainer__updateContainer__colon">:</div>
                  <input readOnly value={`${updateData.available_quantity} + ${newQuty} `} className="UpdateFormContainer__updateContainer__value" /></div> :
                <div className="UpdateFormContainer__updateContainer__subContainer">
                  <div className="UpdateFormContainer__updateContainer__lable">Quantity</div>
                  <div className="UpdateFormContainer__updateContainer__colon">:</div>
                  <input readOnly value={`${updateData.available_quantity} - ${newQuty} `} className="UpdateFormContainer__updateContainer__value" /></div>}</>}
          <div className="UpdateFormContainer__updateContainer__subContainer">
            <div className="UpdateFormContainer__updateContainer__lable">Name</div>
            <div className="UpdateFormContainer__updateContainer__colon">:</div>
            <input type="text" value={updateData.name}
              onChange={(e) => { handleUpdateInput(e, "name") }}
              className="UpdateFormContainer__updateContainer__itemData" /></div>
          <div className="UpdateFormContainer__updateContainer__subContainer">
            <div className="UpdateFormContainer__updateContainer__lable">Update Quty</div>
            <div className="UpdateFormContainer__updateContainer__colon">:</div>
            <input type="number"
              value={newQuty}
              onChange={(e) => { setNewQuty(e.target.value) }}
              className="UpdateFormContainer__updateContainer__itemData2" />
            <button className="UpdateFormContainer__updateContainer__btn" onClick={() => {  setCalculationKey("Add"); handelQuantityUpdate(updateData._id, "Add") }}>+</button>
            <button className="UpdateFormContainer__updateContainer__btn" onClick={() => {  setCalculationKey("Sub"); handelQuantityUpdate(updateData._id, "Sub") }}>-</button></div>
          <div className="UpdateFormContainer__updateContainer__subContainer">
            <div className="UpdateFormContainer__updateContainer__lable">Weight (gms)</div>
            <div className="UpdateFormContainer__updateContainer__colon">:</div>
            <input type="number"
              value={updateData.weight}
              min={1}
              onChange={(e) => { handleUpdateInput(e, "weight") }}
              className="UpdateFormContainer__updateContainer__itemData" />
          </div>
        </div>
      </div>
      <div className="UpdateFormContainer__Options">
        <div onClick={handleCancel} id="cancelBtn">Cancel</div>
        <div onClick={() => handleUpdateButton(updateData._id)} id="OkayBtn">OK</div>
      </div>
    </div>
  )
}
export default UpdateForm;