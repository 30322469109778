import React, { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import "./HelpManual.scss";
import { pdfjs } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;



const HelpManual = ({
  SetCategories,
  userPermission,
  userCookie,
  SetLoadingVisibility,
  handleNotifyAlert,
}) => {
  const pdfTopics = userPermission === "Granted" ? [
    "To REGISTER On Ecommerce website Follow These Steps",
    "To LOGIN on Ecommerce Website Follow These Steps",
    "To Add CATEGORY Follow These Steps",
    "To add item to INVENTORY follow these steps",
    "To Edit Inventory Item Follow These Steps",
    "To Delete Inventory Item Follow These Steps",
    "To Delete Category Follow These Steps",
    "To Place Order For Customer (Other PHC staff) By Inventory Manager",
    "To View Orders Placed By Customer (Other PHC staff)",
    "To Update Order Status For Specific Customer Order",
    "To Cancel any specific Customer Order",
    "To View User Profile Follow These Steps",
    "To Change Your Account Password Follow These Steps",
    "To Update Your Profile Follow These Steps"
  ] : [
    "To REGISTER On Ecommerce website Follow These Steps",
    "To LOGIN on Ecommerce Website Follow These Steps",
    "To Add Items To Bag Follow These Steps",
    "To Place an Order Follow These Steps",
    "To View All Orders And their Status Follow These Steps",
    "To View Order Flight Follow These Steps",
    "To Accept The Delivery Of Order Follow These Steps",
    "To View User Profile Follow These Steps",
    "To Change Your Account Password Follow These Steps",
    "To Update Your Profile Follow These Steps"
  ]
  const pdfTopicsPages = userPermission === "Granted" ? {
    "To REGISTER On Ecommerce website Follow These Steps": [1,2],
    "To LOGIN on Ecommerce Website Follow These Steps": [3,4],
    "To Add CATEGORY Follow These Steps": [5,6],
    "To add item to INVENTORY follow these steps": [7,8],
    "To Edit Inventory Item Follow These Steps": [9,10,11,12],
    "To Delete Inventory Item Follow These Steps": [13,14,15],
    "To Delete Category Follow These Steps": [16,17,18],
    "To Place Order For Customer (Other PHC staff) By Inventory Manager": [19,20,21],
    "To View Orders Placed By Customer (Other PHC staff)": [22,23,24,25],
    "To Update Order Status For Specific Customer Order": [26,27],
    "To Cancel any specific Customer Order": [28,29,30],
    "To View User Profile Follow These Steps": [31],
    "To Change Your Account Password Follow These Steps": [32,33],
    "To Update Your Profile Follow These Steps": [34,35]
  } : {
    "To REGISTER On Ecommerce website Follow These Steps": [1, 2],
    "To LOGIN on Ecommerce Website Follow These Steps": [3, 4],
    "To Add Items To Bag Follow These Steps": [5, 6],
    "To Place an Order Follow These Steps": [7, 8, 9, 10, 11, 12],
    "To View All Orders And their Status Follow These Steps": [13, 14, 15],
    "To View Order Flight Follow These Steps": [16, 17, 18, 19],
    "To Accept The Delivery Of Order Follow These Steps": [20, 21, 22],
    "To View User Profile Follow These Steps": [23],
    "To Change Your Account Password Follow These Steps": [24, 25],
    "To Update Your Profile Follow These Steps": [26, 27]
  }
  const pdfLink = userPermission === "Granted"?"https://images.techeagle.org/documents/SOP-Inventory-Manager.pdf":"https://images.techeagle.org/documents/SOP-User.pdf"

  const [currentTopic, setCurrentTopic] = useState(pdfTopics[0])
  const [pagesToShow, setPagesToShow] = useState([]);
  const [pageWidth, setPageWidth] = useState([]);
  const pdfDocRef = useRef()
  const canvasRef = useRef(null);
  const [isPdfLoaded, setIsPdfLoaded] = useState(true);
  if (!isPdfLoaded) {
    SetLoadingVisibility(true)
  }

  // useEffect(() => {
  //   setPagesToShow(pdfTopicsPages[currentTopic])
  //   pdfDocRef.current.scrollIntoView({ behavior: 'smooth' })
  // }, [currentTopic])
  const onDocumentLoadSuccess = () => {
    console.log("document loaded")
    setIsPdfLoaded(true)
    // SetLoadingVisibility(false);
  }

  useEffect(() => {
    setPagesToShow(pdfTopicsPages[currentTopic])
    pdfDocRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [currentTopic])
  useEffect(() => {
    if (pdfDocRef.current) {
      console.log("clientWidth", pdfDocRef.current.clientWidth)
      setPageWidth(pdfDocRef.current?.clientWidth * 0.95);
    }

  }, [pdfDocRef.current?.clientWidth])

  useEffect(() => {
    if (isPdfLoaded)
      SetLoadingVisibility(false);
  }, [isPdfLoaded])
  useEffect(() => {
    if (pdfDocRef.current)
      pdfDocRef.current.scrollTop = 0;
  }, [currentTopic])


  return (
    <div className="HelpManualContainer">
      <div className="HelpManual">
        {<div className="HelpManual__title">{userPermission==="Granted"?"Inventory Manager Manual":"User Manual"}</div>}
        <div className="HelpManual__doc-container">
          <div className="HelpManual__doc-container__links-container">
            {pdfTopics.map((topic, i) => {
              return <><button key={i} className={`HelpManual__doc-container__links-container__${currentTopic === topic? "focusedLink": "link"}`}
              onClick={() => { setCurrentTopic(topic) }}>{i+1}. {topic}</button>
              <div className="HelpManual__doc-container__links-container__borderdiv"></div>
              </>
            })}
          </div>
          <div className="HelpManual__doc-container__rightPanel">
            {/* <div >{currentTopic}</div> */}
            <div ref={pdfDocRef} className="HelpManual__doc-container__pdf-container">

              {pagesToShow.map((page) => {
                return <Document file={pdfLink} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={page}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    customTextRenderer={false}
                    width={pageWidth}
                  />
                </Document>
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpManual;
