import React, { useRef, useState } from "react";
import "./UpdateProfile.scss";
import { backEndIP } from "../configuration";
import axios from "axios";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import dayjs from "dayjs";
function UpdateUserProfile({
  setOpenModal,
  props,
  setUpdateSelf,
  userCookie,
  SetLoadingVisibility,
  handleNotifyAlert,
}) {
  const modalRef = useRef();
  const [updateProfileData, setUpdateProfileData] = useState({
    phone_no: props.phone_no,
    gender: props.gender,
    date_birth: dayjs(props.date_birth).format("YYYY-MM-DD").toString(),
  });
  const dateElementRef = useRef()
  const monthElementRef = useRef()
  const yearElementRef = useRef()
  const phoneNoElementRef = useRef()
  
  const closeModal = (e) => {
    setOpenModal(false)
  };
  const index = {
    "0" : "dateElementRef",
    "1" : "phone_no",
  }

  const checkDataUpdate = async(array) =>{
    let changedData = []
   // console.log(array)
    array.forEach((data, i)=>{
      if(data.current.value !== ""){
       // console.log(data.current.value)
        changedData.push(index[i])
      }
    })
    return changedData
  }

  const handelProfileUpdate = async() => {
    SetLoadingVisibility(true)
    const updatedData = await checkDataUpdate([dateElementRef, phoneNoElementRef])
    if(updatedData.length){
      updatedData.forEach((data, i)=>{
        console.log("phone_no", phoneNoElementRef.current.value)
        if(data === "dateElementRef"){
         updateProfileData.date_birth = `${dateElementRef.current.value}/${monthElementRef.current.value}/${yearElementRef.current.value}`
        } else if (data === "phone_no") {
          console.log("phone_no", phoneNoElementRef.current.value)
         updateProfileData.phone_no = `+91${phoneNoElementRef.current.value}`
        }
      })
      console.log("updateProfileData", updateProfileData)
      axios({
        url: backEndIP + "/user/update_BasicProfile",
        method: "PUT",
        headers: {
          authorization: userCookie.token,
        },
        data: {
          phone_no: updateProfileData.phone_no,
          gender: updateProfileData.gender,
          date_birth: updateProfileData.date_birth,
        }
      }).then((resp) => {
        console.log(resp.data.data)
        SetLoadingVisibility(false);
        setOpenModal(false)
        handleNotifyAlert(
          "notify",
          `${resp.data.message}`,
          "success",
          () => { }
        );
        setUpdateSelf(prev=>!prev)
      }).catch((err) => {
        console.log(err)
        SetLoadingVisibility(false);
        //  setOpenModal(false)
        handleNotifyAlert(
          "alert",
          `${err.response.data.message}`,
          "failed",
          () => { setOpenModal(false) }
        );
      })
    } else {
      axios({
        url: backEndIP + "/user/update_BasicProfile",
        method: "PUT",
        headers: {
          authorization: userCookie.token,
        },
        data: {
          phone_no: updateProfileData.phone_no,
          gender: updateProfileData.gender,
          date_birth: updateProfileData.date_birth,
        }
      }).then((resp) => {
        console.log(resp.data.data)
        SetLoadingVisibility(false);
        setOpenModal(false)
        handleNotifyAlert(
          "notify",
          `${resp.data.message}`,
          "success",
          () => { }
        );
        setUpdateSelf(prev=>!prev)
      }).catch((err) => {
        console.log(err)
        SetLoadingVisibility(false);
        //  setOpenModal(false)
        handleNotifyAlert(
          "alert",
          `${err.response.data.message}`,
          "failed",
          () => { setOpenModal(false) }
        );
      })
    }
  
     setUpdateSelf(prev=>!prev)
  }
  const handleDateChange = (e) => {
    if (dateElementRef.current) {
      if (e.target.value.length >= 2) {
        monthElementRef.current.value = e.target.value.slice(2)
        dateElementRef.current.value = e.target.value.slice(0, 2)
        monthElementRef.current.focus()

      }
    }
  }
  const handleMonthChange = (e) => {
    if (monthElementRef.current) {
      if (e.target.value.length >= 2) {
        yearElementRef.current.value = e.target.value.slice(2)
        monthElementRef.current.value = e.target.value.slice(0, 2)
        yearElementRef.current.focus()

      }
      else if (e.target.value.length === 0) {
        dateElementRef.current.focus()
      }
    }
  }



  return (
    <div ref={modalRef} className="updateUserProfile-modalBackground">
      <div className="updateUserProfile-modalContainer" >
        <div className="updateUserProfile-modalContainer__titleContainer">Update Your Profile
          <div onClick={(e) => { closeModal(e) }} className="updateUserProfile-modalContainer__titleContainer__cancelBtn">X</div></div>
        <div className="updateUserProfile-custom-alert-box-msg">
          <div className="updateUserProfile-custom-alert-box-msg__inputFiled">
            <div className="updateUserProfile-custom-alert-box-msg__inputFiled__label">Name</div>
            <div className="updateUserProfile-custom-alert-box-msg__inputFiled__value">
              <input type="text"
                value={props.userName}
                //placeholder={props.username}
                className="updateUserProfile-custom-alert-box-msg__inputFiled__value__input"
                readOnly
              /></div>
          </div>
          <div className="updateUserProfile-custom-alert-box-msg__inputFiled">
            <div className="updateUserProfile-custom-alert-box-msg__inputFiled__label">Email </div>
            <div className="updateUserProfile-custom-alert-box-msg__inputFiled__value">
              <input type="text"
                value={props.useremail}
                // placeholder="Enter New Password"
                className="updateUserProfile-custom-alert-box-msg__inputFiled__value__input"
                readOnly
              /></div>
          </div>
          <div className="updateUserProfile-custom-alert-box-msg__inputFiled">
            <div className="updateUserProfile-custom-alert-box-msg__inputFiled__label">Phone No</div>
            <div className="updateUserProfile-custom-alert-box-msg__inputFiled__value">
              
              {updateProfileData.phone_no === "" ? 
              <><input type="text"
              value={"+91"}
              //placeholder="Enter Confirm Password"
              style={{ width: '10%' }}
              className="updateUserProfile-custom-alert-box-msg__inputFiled__value__input" readOnly />
              <input ref={phoneNoElementRef}
                type="number"
                autoFocus={true}
                placeholder="Enter Phone No"
                className="updateUserProfile-custom-alert-box-msg__inputFiled__value__input"
                onChange={(e) => {
                  if (e.target.value.length > 10) {
                    phoneNoElementRef.current.value = e.target.value.slice(0, 10)
                  }
                }}
              /></> : <>
              <input type="text"
              value={updateProfileData.phone_no.slice(0, 3)}
              //placeholder="Enter Confirm Password"
              style={{ width: '10%' }}
              className="updateUserProfile-custom-alert-box-msg__inputFiled__value__input" readOnly />
              <input ref={phoneNoElementRef}
                type="number"
                autoFocus={true}
                placeholder={updateProfileData.phone_no.slice(3)}
                className="updateUserProfile-custom-alert-box-msg__inputFiled__value__input"
                onChange={(e) => {
                  if (e.target.value.length > 10) {
                    phoneNoElementRef.current.value = e.target.value.slice(0, 10)
                  }
                }}
              /></>}
            </div>
          </div>
          <div className="updateUserProfile-custom-alert-box-msg__inputFiled">
            <div className="updateUserProfile-custom-alert-box-msg__inputFiled__label">Date Of Birth</div>
            {props.date_birth === null ? <div className="updateUserProfile-custom-alert-box-msg__inputFiled__value">
              <input ref={dateElementRef}
                type="number"
                placeholder="DD"
                className="updateUserProfile-custom-alert-box-msg__inputFiled__value__date"
                minLength={2}
                maxLength={2}
                max={2}
                onChange={handleDateChange}
              // onSubmitEditing={() => monthElementRef.current.focus()}
              />/
              <input ref={monthElementRef}
                type="number"
                placeholder="MM"
                className="updateUserProfile-custom-alert-box-msg__inputFiled__value__month"
                minLength={2}
                maxLength={2}
                max={2}
                onChange={handleMonthChange}
              />/
              <input ref={yearElementRef}
                type="number"
                placeholder="YYYY"
                className="updateUserProfile-custom-alert-box-msg__inputFiled__value__year"
                minLength={4}
                maxLength={4}
                max={4}
                onChange={(e) => {
                  if (e.target.value.length > 4) {
                    yearElementRef.current.value = e.target.value.slice(0, 4)
                  }
                  if (e.target.value.length === 0) {
                    monthElementRef.current.focus()
                  }
                }}
              />
            </div> : <div className="updateUserProfile-custom-alert-box-msg__inputFiled__value">
              <input ref={dateElementRef}
                type="number"
                placeholder={updateProfileData.date_birth.split("-")[1]}
                className="updateUserProfile-custom-alert-box-msg__inputFiled__value__date"
                minLength={2}
                maxLength={2}
                max={2}
                onChange={handleDateChange}
              // onSubmitEditing={() => monthElementRef.current.focus()}
              />/
              <input ref={monthElementRef}
                type="number"
                placeholder={updateProfileData.date_birth.split("-")[2]}
                className="updateUserProfile-custom-alert-box-msg__inputFiled__value__month"
                minLength={2}
                maxLength={2}
                max={2}
                onChange={handleMonthChange}
              />/
              <input ref={yearElementRef}
                type="number"
                placeholder={updateProfileData.date_birth.split("-")[0]}
                className="updateUserProfile-custom-alert-box-msg__inputFiled__value__year"
                minLength={4}
                maxLength={4}
                max={4}
                onChange={(e) => {
                  if (e.target.value.length > 4) {
                    yearElementRef.current.value = e.target.value.slice(0, 4)
                  }
                  if (e.target.value.length === 0) {
                    monthElementRef.current.focus()
                  }
                }}
              />
            </div>}
          </div>
          <div className="updateUserProfile-custom-alert-box-msg__inputRadio">
            <div className="updateUserProfile-custom-alert-box-msg__inputRadio__label">Gender</div>
            <FormControl>
            <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={updateProfileData.gender}
            onChange={(e)=>{setUpdateProfileData({...updateProfileData, ["gender"]: e.target.value})}}
            className="updateUserProfile-custom-alert-box-msg__inputRadio__value"
            >
            <FormControlLabel className="updateUserProfile-custom-alert-box-msg__inputRadio__value__input" value="Male" control={<Radio style={{ color: "#4285F4" }} />} label="Male" />
            <FormControlLabel className="updateUserProfile-custom-alert-box-msg__inputRadio__value__input" value="Female" control={<Radio style={{ color: "#4285F4" }} />} label="Female" />
            <FormControlLabel className="updateUserProfile-custom-alert-box-msg__inputRadio__value__input" value="Other" control={<Radio style={{ color: "#4285F4" }} />} label="Other" />
          </RadioGroup>
        </FormControl>
          </div>
        </div>
        <div className="updateUserProfile-custom-alert-box-button-container">
          <div onClick={handelProfileUpdate} className="updateUserProfile-custom-alert-box-button-container__ChangePasswordBtn">Submit</div>
        </div>
      </div>
    </div>

  );
}

export default UpdateUserProfile;
