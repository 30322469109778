import axios from "axios";
import { React, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { backEndIP } from '../configuration'
import "./Register.scss"
import CustomNotification from "../CustomNotification/CustomNotification";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import CustomAlertBox from "../CustomAlert/CustomAlertBox";
const Register = (props) => {

  const { setuserCookie, userCookie, themeGlobal, setUserName } = props;
  const usernameElementRef = useRef();
  const userEmailElementRef = useRef();
  const passwordElementRef = useRef();
  const confirmPasswordElementRef = useRef();
  const phoneNoElementRef = useRef();
  const hublocationElementRef = useRef();
  const locationElementRef = useRef();
  const [selectCountry, setSelectCountry] = useState("");
  const [selectZone, setSelectZone] = useState("");
  const [selectState, setSelectState] = useState("");
  const [selectLocation, setSelectLocation] = useState("");
  const [selectHub, setSelectHub] = useState("");
  const errorMessageElementRef = useRef();
  const navigate = useNavigate();
  const [notificationVisibility, setNotificationVisibility] = useState(false);
  const [alertVisibility, setAlertVisibility] = useState(false);
  const [messageToDisplay, setMessageToDisplay] = useState({
    type: "notify",
    message: "msg",
    msgtype: "success",
    func: () => { },
  });
  const [loadingVisibility, setLoadingVisibility] = useState(false);
  const [allLocations, setAllLocations] = useState([])

  useEffect(() => {
    axios({
      url: backEndIP + "/user/get_all_locations",
      method: "GET",

    }).then((resp) => {
      console.log(resp.data);
      setAllLocations(resp.data.data);
      // setCategory(data.data.data[0].name)
    }).catch((err) => {
      console.log(err)
    })
  }, []);

  //console.log("allLocations =", allLocations)

  const handleUpdateAlertNottifyMsg = (type, message, msgtype, func) => {
    setMessageToDisplay({
      type: type,
      message: message,
      msgtype: msgtype,
      func: () => {
        setLoadingVisibility(false);
        func();
      },
    });
    if (type === "notify") {
      setNotificationVisibility(true);
    } else if (type === "alert") {
      setNotificationVisibility(true);
    }
  };

const allRefs = [usernameElementRef,userEmailElementRef,passwordElementRef,confirmPasswordElementRef,phoneNoElementRef,hublocationElementRef,locationElementRef]
  const handleRegisterBtnClick = () => {
    let allDataAvailableFlag = allRefs.every((ref)=>{return ref.current!==undefined})
    console.log("allDataAvailableFlag =",allDataAvailableFlag)
    if(allDataAvailableFlag){
    let username = usernameElementRef.current.value;
    let userEmail = userEmailElementRef.current.value;
    let password = passwordElementRef.current.value;
    let confirmPassword = confirmPasswordElementRef.current.value;
    let phoneNo = phoneNoElementRef.current.value;
    let hub_location = hublocationElementRef.current.value;
    let delivery_location = locationElementRef.current.value;
    console.log(username, password, confirmPassword, phoneNo)
    if (username.length >= 6 && password.length > 5 && userEmail.length > 5) {
      if (password === confirmPassword && password.length >= 6) {
        if (phoneNo === "") {
          errorMessageElementRef.current.innerHTML = "Please Enter Your Phone No"
          errorMessageElementRef.current.style.opacity = 1;
        } else if (phoneNo.length < 10) {
          errorMessageElementRef.current.innerHTML = "Please Enter a valid Phone No"
          errorMessageElementRef.current.style.opacity = 1;
        } else {
          if (selectHub === "" && selectLocation === "") {
            errorMessageElementRef.current.innerHTML = "Please select Nearest hub and Your location"
            errorMessageElementRef.current.style.opacity = 1;
          } else if (selectHub === "") {
            errorMessageElementRef.current.innerHTML = "Please select Nearest hub"
            errorMessageElementRef.current.style.opacity = 1;
          } else if (selectLocation === "") {
            errorMessageElementRef.current.innerHTML = "Please select Your Location"
            errorMessageElementRef.current.style.opacity = 1;
          } else {
            errorMessageElementRef.current.style.opacity = 0;
            setLoadingVisibility(true)
            axios({
              // url: "https://dineshborse.in/user/register",
              url: backEndIP + "/user/register",
              method: "POST",
              headers: {
              },
              data: {
                useremail: userEmail,
                username: username,
                password: password,
                phoneNo: `+91${phoneNo}`,
                location: hub_location,
                delivery_location: delivery_location,
              }
            }).then(async (loginData) => {
              console.log(loginData)
              setLoadingVisibility(false)
              handleUpdateAlertNottifyMsg("alert",
                `User ${username} added successfully, Please check your Email`,
                "success", () => { navigate("/") })
              // navigate("/")

            }).catch((err) => {
              console.log(err)
              setLoadingVisibility(false)
              handleUpdateAlertNottifyMsg("notify", `${err.response.data.message}`, "failed", () => { })
            })
          }

        }
      }
      else {
        if (password.length < 6) {
          errorMessageElementRef.current.innerHTML = "minimum length of password should be 6"
          errorMessageElementRef.current.style.opacity = 1;
        }
        else {
          errorMessageElementRef.current.innerHTML = "password does not match"
          errorMessageElementRef.current.style.opacity = 1;
        }

      }
    }
    else {
      if (username.length < 6) {
        errorMessageElementRef.current.innerHTML = "minimum length of username should be 6"
        errorMessageElementRef.current.style.opacity = 1;
      }
      else {
        errorMessageElementRef.current.innerHTML = "minimum length of password should be 6"
        errorMessageElementRef.current.style.opacity = 1;
      }
    }
  } else {
    errorMessageElementRef.current.innerHTML = "All fields are Mandatory"
    errorMessageElementRef.current.style.opacity = 1;
  }
  }

  return (
    <div className="register-page-body" style={{ height: "100vh", width: "100vw" }}>
      <div className="register-page-body__leftHalf">
        <div className="register-page-body__leftHalf__welcomeBox">
          <img className="register-page-body__leftHalf__welcomeBox__logo"
            src={themeGlobal.EagleLogo} alt="logo"
          >
          </img>
          <div className="register-page-body__leftHalf__welcomeBox__welcomeMsg">Welcome</div>
          <div className="register-page-body__leftHalf__welcomeBox__register-message">To Register Please Fill the form Below</div>
        </div>
        <div className="register-page-body__leftHalf__register-container">
          <input ref={usernameElementRef} className="register-page-body__leftHalf__register-container__input-email" type={"text"} placeholder={"User Name"}></input>
          <input ref={userEmailElementRef} className="register-page-body__leftHalf__register-container__input-password" type={"email"} placeholder={"User Email Id"}></input>
          <input ref={passwordElementRef} className="register-page-body__leftHalf__register-container__input-email" type={"password"} placeholder={"Password"}></input>
          <input ref={confirmPasswordElementRef} className="register-page-body__leftHalf__register-container__input-password" type={"password"} placeholder={"Confirm Password"}></input>
          <div className="register-page-body__leftHalf__register-container__input-email">
            <input readOnly type="text"
              value={"+91"}
              className="register-page-body__leftHalf__register-container__input-email__valueReadOnly"
              style={{ width: '10%' }}></input>
            <input
              ref={phoneNoElementRef}
              type="number"
              placeholder={"Phone Number"}
              className="register-page-body__leftHalf__register-container__input-email__value"
              onChange={(e) => {
                if (e.target.value.length > 10) {
                  phoneNoElementRef.current.value = e.target.value.slice(0, 10)
                }
              }}></input>
          </div>
          {allLocations.length ? null : <><div className="register-page-body__leftHalf__register-container__field">
            <div className="register-page-body__leftHalf__register-container__field__title">Country</div>
            <div className="register-page-body__leftHalf__register-container__field__colon">:</div>
            <div className="register-page-body__leftHalf__register-container__field1__value">
              <select className="register-page-body__leftHalf__register-container__field__value__option"
                onChange={(e) => {
                  if (e.currentTarget.value !== "") {
                    setSelectCountry(allLocations.countryNames[e.currentTarget.value])
                    // setNewHubData((prev) => {
                    //   console.log("select", selectCountry)
                    //   let obj = { ...prev };
                    //   obj[content.drop_down[0].key] = e.target.value;
                    //   return obj;
                    // });
                  } else {
                    setSelectCountry("");
                    setSelectZone("");
                    setSelectState("");
                    setSelectHub("");
                    setSelectLocation("");
                  }
                  //console.log("country", e.currentTarget.value);
                }}>
                <option value={""} className="register-page-body__leftHalf__register-container__field__value__option"></option>
                {allLocations.country?.map((item) => {
                  return <option value={item._id} className="register-page-body__leftHalf__register-container__field__value__option">{item.country_name}</option>
                })}
              </select>
            </div>
          </div>
            {selectCountry !== "" && <div className="register-page-body__leftHalf__register-container__field1">
              <div className="register-page-body__leftHalf__register-container__field1__title">Zone</div>
              <div className="register-page-body__leftHalf__register-container__field1__colon">:</div>
              <div className="register-page-body__leftHalf__register-container__field1__value">
                <select className="register-page-body__leftHalf__register-container__field1__value__option"
                  onChange={(e) => {
                    if (e.currentTarget.value !== "") {
                      setSelectZone(allLocations.zonesNames[e.currentTarget.value])
                      // setNewHubData((prev) => {
                      //   console.log("select", selectZone)
                      //   let obj = { ...prev };
                      //   obj[content.drop_down[1].key] = e.target.value;
                      //   return obj;
                      // });
                    }
                    else {
                      setSelectZone("");
                      setSelectState("");
                      setSelectHub("");
                      setSelectLocation("");
                    }
                    //console.log("Zone", e.currentTarget.value);
                  }}>
                  <option className="register-page-body__leftHalf__register-container__field1__value__option" value={""}></option>
                  {allLocations.zones.map((item) => {
                    return <option value={item.zone_id} className="register-page-body__leftHalf__register-container__field1__value__option">{item.zone_name}</option>
                  })}
                </select>
              </div>
            </div>}
            {selectZone !== "" && <div className="register-page-body__leftHalf__register-container__field">
              <div className="register-page-body__leftHalf__register-container__field__title">State</div>
              <div className="register-page-body__leftHalf__register-container__field__colon">:</div>
              <div className="register-page-body__leftHalf__register-container__field__value">
                <select className="register-page-body__leftHalf__register-container__field__value__option"
                  onChange={(e) => {
                    if (e.currentTarget.value !== "") {
                      setSelectState(allLocations.stateNames[e.currentTarget.value])
                      // setNewHubData((prev) => {
                      //   console.log("select", selectState)
                      //   let obj = { ...prev };
                      //   obj[content.drop_down[2].key] = e.target.value;
                      //   return obj;
                      // });
                    }
                    else {
                      setSelectState("");
                      setSelectHub("");
                      setSelectLocation("");
                    }
                    //console.log("State", e.currentTarget.value);
                  }}>
                  <option value={""} className="register-page-body__leftHalf__register-container__field__value__option"></option>
                  {allLocations.states[selectZone].map((item) => {
                    return <option value={item.state_id} className="register-page-body__leftHalf__register-container__field__value__option">{item.state_name}</option>
                  })}
                </select>
              </div>
            </div>
            }
            {selectState !== "" && <div className="register-page-body__leftHalf__register-container__field1">
              <div className="register-page-body__leftHalf__register-container__field1__title">Nearest Hub</div>
              <div className="register-page-body__leftHalf__register-container__field1__colon">:</div>
              <div className="register-page-body__leftHalf__register-container__field1__value">
                <select className="register-page-body__leftHalf__register-container__field1__value__option"
                  ref={hublocationElementRef}
                  onChange={(e) => {
                    if (e.currentTarget.value !== "") {
                      setSelectHub(allLocations.hubNames[e.currentTarget.value])
                      console.log(allLocations.hubNames[e.currentTarget.value])
                      // setNewHubData((prev) => {
                      //   console.log("select", selectState)
                      //   let obj = { ...prev };
                      //   obj[content.drop_down[2].key] = e.target.value;
                      //   return obj;
                      // });
                    }
                    else {
                      setSelectHub("");
                      setSelectLocation("");
                    }
                    console.log("Hub", e.currentTarget.value);
                  }}>
                  <option value={""} className="register-page-body__leftHalf__register-container__field1__value__option"></option>
                  {allLocations.hubs[selectState].map((item) => {
                    // console.log(item)
                    return <option value={item._id} className="register-page-body__leftHalf__register-container__field1__value__option">{`${item.location_name}(${item.tag_name})`}</option>
                  })}
                </select>
              </div>
            </div>
            }
            {selectHub !== "" && <div className="register-page-body__leftHalf__register-container__field">
              <div className="register-page-body__leftHalf__register-container__field__title">User's Location</div>
              <div className="register-page-body__leftHalf__register-container__field__colon">:</div>
              <div className="register-page-body__leftHalf__register-container__field__value">
                <select className="register-page-body__leftHalf__register-container__field__value__option"
                  ref={locationElementRef}
                  onChange={(e) => {
                    if (e.currentTarget.value !== "") {
                      setSelectLocation(allLocations.locationName[e.currentTarget.value])
                    }
                    else {
                      setSelectLocation("");
                    }
                    //console.log("Location", e.currentTarget.value);
                  }}>
                  <option value={""} className="register-page-body__leftHalf__register-container__field__value__option"></option>
                  {allLocations.locations[selectHub].map((item) => {
                    return <option value={item.location_id} className="register-page-body__leftHalf__register-container__field__value__option">{item.location_name}</option>
                  })}
                  {/* <option value={hublocationElementRef.current.value} className="register-page-body__leftHalf__register-container__field__value__option">{selectHub}</option> */}
                </select>
              </div>
            </div>
            }
          </>}
          {/* <div className="login-page-body__leftHalf__login-container__forgotPassword">Forgot password?</div> */}
          <div onClick={handleRegisterBtnClick}
            className="register-page-body__leftHalf__register-container__btn-SignIn">Sign Up</div>
          <div
            className="register-page-body__leftHalf__register-container__register-msg1">
            Already have an account?
            <span
              className="register-page-body__leftHalf__register-container__register-msg2"
              onClick={() => { navigate("/") }}
            >Login
            </span>
          </div>
          <div ref={errorMessageElementRef}
            className="register-page-body__leftHalf__register-container__error-msg"
          >message</div>
        </div>
      </div>
      <div className="register-page-body__rightHalf">
        <img className="register-page-body__rightHalf__img"
          src="drone_in_ancient_cave_processed.png" alt="img"></img>
      </div>
      {notificationVisibility && (
        <CustomNotification
          setOpenModal={setNotificationVisibility}
          messageToDisplay={messageToDisplay}
        />
      )}
      {loadingVisibility && <LoadingScreen />}

    </div>
  )
}


export default Register