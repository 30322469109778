import React, { createContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CookiesProvider, useCookies } from "react-cookie";
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Register from "./components/Register/Register";
import Login from "./components/Login/Login";
import './App.scss';
import HomePage from './components/HomePage';
import AdminLogin from './components/AdminLogin/AdminLogin';
import AdminRegister from './components/AdminRegister/AdminRegister';
import AdminHomePage from './components/AdminHomePage/AdminHomePage';
import { isDesktop, isMobile } from 'react-device-detect';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';



function App() {


  const themesAvailable = {
    light: {
      "--primaryColor": "white",
      "--secondaryColor": "#F2F2F2",
      "--primaryTextColor": "#08030A",
      "--secondaryTextColor": "#e2e2e2"
    },
    dark: {
      "--primaryColor": "#000",
      "--secondaryColor": "#101010",
      "--primaryTextColor": "white",
      "--secondaryTextColor": "#e2e2e2"
    }
  };
  const [nameLogo, setNameLogo] = useState('TE-logoName.svg');
  const [EagleLogo, SetEagleLogo] = useState('TechEagle_Logo.svg');
  const [userTokenCookie, setUserTokenCookie, removeUserTokenCookie] = useCookies(["token"]);
  const [userName, setUserName] = useState(userTokenCookie.username);
  const MovingRingRef = useRef();
  const [themeColor, SetThemeColor] = useState("light")
  // const ThemeContext = createContext({ themeColor: themeColor, SetThemeColor: SetThemeColor })
  const [theme, setTheme] = useState(-1)

  const dimentionMultiplier = 100;
  const [referHeight, setReferHeight] = useState(1080);
  const [referWidth, setReferWidth] = useState(1920);
  document.body.style.fontSize = ((window.innerHeight / 1080) * 16).toString() + "px";
  const [SizeMultiplier, setSizeMultiplier] = useState({
    "--widthmultiplier": (parseInt(window.innerWidth * dimentionMultiplier / 1920) / dimentionMultiplier),
    "--heightmultiplier": (parseInt(window.innerHeight * dimentionMultiplier / 1080) / dimentionMultiplier),
    height: window.innerHeight.toString() + "px",
    width: window.innerWidth.toString() + "px"
  });


  // useEffect(() => {
  //   if(isMobile){
  //     setReferHeight(844);
  //     setReferWidth(390);
  //     console.log("mobile")
  //   }
  //   if(isDesktop){
  //     setReferHeight(1080);
  //     setReferWidth(1920);
  //     console.log("desktop")
  //   }
  // }, [isMobile,isDesktop])





  useLayoutEffect(() => {

    function handleResize() {

      if (isDesktop) {
        let calFontHeight = window.innerHeight.toString() + "px";
        let calFontWidth = window.innerWidth.toString() + "px";
        let calMulHeight = (parseInt(window.innerHeight * dimentionMultiplier / (isDesktop ? 1080 : 844)) / dimentionMultiplier);
        let calmulWidth = (parseInt(window.innerWidth * dimentionMultiplier / (isDesktop ? 1920 : 390)) / dimentionMultiplier);
        setSizeMultiplier({ "--widthmultiplier": calmulWidth, "--heightmultiplier": calMulHeight, height: calFontHeight, width: calFontWidth });
        console.log(calmulWidth, calMulHeight);
        console.log(calFontHeight, calFontWidth);
        document.body.style.fontSize = ((window.innerHeight / (isDesktop ? 1080 : 844)) * 16).toString() + "px";
        // document.body.style.width = window.innerWidth.toString() + "px";
        // document.body.style.height = window.innerHeight.toString() + "px";
        // console.log(SizeMultiplier)
      }

      if (isMobile) {
        let calFontHeight = window.innerHeight.toString() + "px";
        let calFontWidth = window.innerWidth.toString() + "px";
        let calMulHeight = (parseInt(window.innerHeight * dimentionMultiplier / 844) / dimentionMultiplier);
        let calmulWidth = (parseInt(window.innerWidth * dimentionMultiplier / 390) / dimentionMultiplier);
        setSizeMultiplier({ "--widthmultiplier": calmulWidth, "--heightmultiplier": calMulHeight, height: calFontHeight, width: calFontWidth });
        console.log(calmulWidth, calMulHeight);
        console.log(calFontHeight, calFontWidth);
        document.body.style.fontSize = ((window.innerHeight / 844) * 16).toString() + "px";
        // document.body.style.width = window.innerWidth.toString() + "px";
        // document.body.style.height = window.innerHeight.toString() + "px";
        // console.log(SizeMultiplier)
      }
    }
    window.addEventListener("resize", handleResize);
    return () => { window.removeEventListener("resize", handleResize) };
  }, []);

  useEffect(() => {

    if (theme === -1) {
      SetThemeColor(themesAvailable.light);
      setNameLogo("TE-logoName.svg");
      SetEagleLogo("TechEagle_Logo.svg")
    }
    else {
      SetThemeColor(themesAvailable.dark);
      setNameLogo("TE-logoNameDark.svg");
      SetEagleLogo("TechEagle_LogoDark.svg")
    }
    console.log(themeColor)

  }, [theme])


  return (
    <div className='app-page-container' style={{
      ...SizeMultiplier,
      "--primaryColor": themeColor["--primaryColor"],
      "--secondaryColor": themeColor["--secondaryColor"],
      "--primaryTextColor": themeColor["--primaryTextColor"],
      "--secondaryTextColor": themeColor["--secondaryTextColor"]
    }}>
      <CookiesProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/all-categories/*" element={<HomePage themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} userName={userName} setUserName={setUserName}
              navSubContent={["/all-categories", ""]} />} />
            <Route path="/baglist/*" element={<HomePage themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} userName={userName} setUserName={setUserName}
              navSubContent={["/baglist", "BagList"]} />} />
            <Route path="/all-orders/*" element={<HomePage themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} userName={userName} setUserName={setUserName}
              navSubContent={["/all-orders", "Orders"]} />} />
            <Route path="/order/*" element={<HomePage themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} userName={userName} setUserName={setUserName}
              navSubContent={["/order/*", "SpecficOrder"]} />} />
            <Route path="/manage-inventory/*" element={<HomePage themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} userName={userName} setUserName={setUserName}
              navSubContent={["/manage-inventory", "Manage Inventory"]} />} />
            <Route path="/myProfile/*" element={<HomePage themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} userName={userName} setUserName={setUserName}
              navSubContent={["/myProfile", "My Profile"]} />} />
            <Route path="/help/*" element={<HomePage themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} userName={userName} setUserName={setUserName}
              navSubContent={["/help", "Help"]} />} />
            <Route path="/" element={<Login themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} setUserName={setUserName} />} />
            <Route path="/forgot-password" element={<ForgotPassword themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} setUserName={setUserName} />} />
            <Route path="/register" element={<Register themeGlobal={{ themeColor: themeColor, nameLogo: nameLogo, EagleLogo: EagleLogo }}
              userCookie={userTokenCookie} setuserCookie={setUserTokenCookie} removeCookie={removeUserTokenCookie} />} />
          </Routes>
        </BrowserRouter>
      </CookiesProvider>
      <div className='app-page-container__themeSwitch'
        onClick={(e) => {
          setTheme((prev) => { return prev * (-1) })
          MovingRingRef.current.style.transform = `translateX(calc(${-10 * theme}px * var(--widthmultiplier)))`
          // console.log(e.currentTarget.style.tra)
        }}
      >
        <div className='app-page-container__themeSwitch__circle'
          ref={MovingRingRef}
        >
        </div>
      </div>
    </div>

  );
}

export default App;
