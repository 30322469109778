import React, { useRef, useState } from "react";
import "./ChangePasswordPopup.scss";
import { backEndIP } from "../configuration";
import axios from "axios";

function ChangePasswordPopup({ setOpenModal,
  userCookie,
  SetLoadingVisibility,
  handleNotifyAlert, }) {
  const modalRef = useRef();
  const [updatePasswordData, setUpdatePasswordData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  })
  //console.log(setOpenModal)
  const closeModal = (e) => {
    setOpenModal(false)
  };

  const handelChangePassword = () => {
    SetLoadingVisibility(true)
    if (updatePasswordData.old_password === "" || updatePasswordData.new_password === "" || updatePasswordData.confirm_password === "") {
      SetLoadingVisibility(false)
      window.alert("Please Fill all required Details")
    } else {
      if (updatePasswordData.new_password.length >= 6) {
        if (updatePasswordData.new_password === updatePasswordData.confirm_password) {
          axios({
            url: backEndIP + "/user/update-password",
            method: "PUT",
            headers: {
              authorization: userCookie.token,
            },
            data: {
              // old_password: updatePasswordData.old_password,
              // new_password: updatePasswordData.new_password
              ...updatePasswordData
            }
          }).then((resp) => {
            console.log(resp.data.data)
            SetLoadingVisibility(false);
            setOpenModal(false)
            handleNotifyAlert(
              "notify",
              `${resp.data.message}`,
              "success",
              () => { }
            );
          }).catch((err) => {
            console.log(err)
            SetLoadingVisibility(false);
            //  setOpenModal(false)
            handleNotifyAlert(
              "alert",
              `${err.response.data.message}`,
              "failed",
              () => { setOpenModal(false) }
            );
          })
        } else {
          SetLoadingVisibility(false)
          window.alert("New password does not match with confirm password")
        }
      } else {
        SetLoadingVisibility(false)
        window.alert("New Password length Should be greater then or equal to 6 Character")
      }

    }
  }


  return (
    <div ref={modalRef} className="changePassword-modalBackground">
      <div className="changePassword-modalContainer" >
        <div className="changePassword-modalContainer__titleContainer">Change Password
          <div onClick={(e) => { closeModal(e) }} className="changePassword-modalContainer__titleContainer__cancelBtn">X</div></div>
        <div className="changePassword-custom-alert-box-msg">
          <div className="changePassword-custom-alert-box-msg__inputFiled">
            <div className="changePassword-custom-alert-box-msg__inputFiled__label">Old password <div style={{ color: "red" }}> * </div></div>
            <div className="changePassword-custom-alert-box-msg__inputFiled__value">
              <input type="password"
                value={updatePasswordData.old_password}
                placeholder="Enter Old Password"
                className="changePassword-custom-alert-box-msg__inputFiled__value__input"
                onChange={(e) => { setUpdatePasswordData({ ...updatePasswordData, ["old_password"]: e.target.value }) }}
              /></div>
          </div>
          <div className="changePassword-custom-alert-box-msg__inputFiled">
            <div className="changePassword-custom-alert-box-msg__inputFiled__label">New password <div style={{ color: "red" }}> * </div></div>
            <div className="changePassword-custom-alert-box-msg__inputFiled__value">
              <input type="password"
                value={updatePasswordData.new_password}
                placeholder="Enter New Password"
                className="changePassword-custom-alert-box-msg__inputFiled__value__input"
                onChange={(e) => { setUpdatePasswordData({ ...updatePasswordData, ["new_password"]: e.target.value }) }}
              /></div>
          </div>
          <div className="changePassword-custom-alert-box-msg__inputFiled">
            <div className="changePassword-custom-alert-box-msg__inputFiled__label">Confirm New password <div style={{ color: "red" }}> * </div></div>
            <div className="changePassword-custom-alert-box-msg__inputFiled__value">
              <input type="password"
                value={updatePasswordData.confirm_password}
                placeholder="Enter Confirm Password"
                className="changePassword-custom-alert-box-msg__inputFiled__value__input"
                onChange={(e) => { setUpdatePasswordData({ ...updatePasswordData, ["confirm_password"]: e.target.value }) }}
              /></div>
          </div>
        </div>
        <div className="changePassword-custom-alert-box-button-container">

          <div onClick={() => { handelChangePassword() }} className="changePassword-custom-alert-box-button-container__ChangePasswordBtn">Change Password</div>
        </div>
      </div>
    </div>

  );
}

export default ChangePasswordPopup;
