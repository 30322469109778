import axios from "axios";
import React from "react";
import { useState } from "react";
import { backEndIP } from "../configuration";
import "./AddCategoryForm.scss"

const AddCategoryForm = ({setAddCategoryPopup, SetCategories, SetLoadingVisibility, userCookie, handleNotifyAlert, setCategories}) => {
    const [category, setCategory] = useState({name:""})

    const handleCancel = ()=>{
        setAddCategoryPopup(false);
    };
    const handleInput =(e, id)=>{
        //setCategory({category, [id]: e.target.value})
        //console.log(e.target.value)
        setCategory({ ...category, [id]: e.target.value });
    }
    //console.log(category);
    const handleSubmit = () =>{
        //console.log("Item Id",_id)
        SetLoadingVisibility(true)
        if(category.name === ""){
            SetLoadingVisibility(false)
            setAddCategoryPopup(false);
            handleNotifyAlert(
                "notify",
                `Please Enter Category Name`,
                "failed",
                () => { }
              );
        } else {
        axios({
          url: backEndIP + `/category/add_category`,
          method: "POST",
          headers: {
            authorization: userCookie.token,
          },
          data: {
            ...category,
          },
          //
        }).then((data) => {
          SetLoadingVisibility(false)
          setAddCategoryPopup(false);
          handleNotifyAlert(
            "notify",
            `Category Added successfully`,
            "success",
            () => { }
          );
          //console.log(data.data.data)
          //SetLoadingVisibility(false)
          SetCategories(data.data.data)
          setCategories(data.data.data)
          //window.location.reload(false);
        })
          .catch((err) => {
            //console.log(err);
            SetLoadingVisibility(false);
            setAddCategoryPopup(false);
            handleNotifyAlert(
              "notify",
              `${err.response.data.message}`,
              "failed",
              () => { }
            );
          });
        }
    }


    return (
        <div className="AddCatagoryForm">
            <div className="AddCatagoryForm__title">Add Category Form</div>
            <div className="AddCatagoryForm__formContainer">
                <div className="AddCatagoryForm__formContainer__lable">Add New Category</div>
                <div className="AddCatagoryForm__formContainer__colon">:</div>
                <input type="text" placeholder="Enter New Category" className="AddCatagoryForm__formContainer__inputBox" onChange={(e)=>{handleInput(e, "name")}} />
            </div>
            <div className="AddCatagoryForm__Options">
                <div onClick={handleCancel} id="cancelBtn">Cancel</div>
                <div onClick={handleSubmit} id="OkayBtn">OK</div>
            </div>
        </div>
    )
}
export default AddCategoryForm;