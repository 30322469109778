import React, { useEffect, useState } from "react";
import "./BasicDetails.scss";
import dayjs from "dayjs";


const BasicDetails = ({
    setPassWordChange,
    setEditBasicDetails,
    userInfo
}) => {

    const locations = {
        "63932c7ab1cab628335ae969": "Jengjal",
        "63930f82865ec3abed90bc1b": "Mendipathar PHC",
        "639310eb865ec3abed90bc1f": "Pedaldoba PHC",
        "639311e2865ec3abed90bc23": "Nongalbibra PHC",
        "6393126c865ec3abed90bc27": "Shallang PHC",
        "639312ff865ec3abed90bc2b": "Samanda PHC",
        "63bd5b3ccafee0c35772f8f1": "Office",
    }


    return (
        <div className="MyProfileBasicDetails-SubConatiner__DataConatiner">
            <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__subContainer">
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__title">
                    <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__title__text">Basic Details</div>
                    <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__title__icon" onClick={()=>{setEditBasicDetails(true)}} >
                        <span className="material-symbols-outlined">
                            edit
                        </span>
                    </div>
                    </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__label">
                    Name
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__value">
                    {userInfo.userName}

                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__label">
                    Email
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__value">
                    {userInfo.useremail}
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__label">
                    Phone No
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__value">
                    {userInfo.phone_no ? userInfo.phone_no : "Not Available"}
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__label">
                    Gender
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__value">
                    {userInfo.gender ? userInfo.gender : "Not Available"}
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__label">
                    Date of Birth
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__value">
                    {userInfo.date_birth ? dayjs(userInfo.date_birth).format("YYYY-MM-DD").toString() : "DD/MM/YY"}
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__label">
                    Connected Hub
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__value">
                    {userInfo.location ? locations[userInfo.location] : "Not Available"}
                </div>
                <button className="MyProfileBasicDetails-SubConatiner__DataConatiner__chngPassBtn" onClick={() => { setPassWordChange(true) }}>Change Password</button>
            </div>
        </div>

    );
};

export default BasicDetails;
