import React, { useRef } from "react";
import "./CustomAlertBox.scss";

function CustomAlertBox({ setOpenModal,messageToDisplay }) {
  const modalRef = useRef();

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setOpenModal(false);
    }
  };


  return (
    <div onClick={closeModal} ref={modalRef} className="modalBackground">
      <div className="modalContainer" >
        <img className="custom-alert-box-img" src="AlertBoxIcon.svg" alt="" />
        {/* <div className="custom-alert-box-name">Alert</div> */}
        <div className="custom-alert-box-msg">{messageToDisplay.message}</div>
        <div className="custom-alert-box-button-container">
          <div onClick={() => { modalRef.current.click(); }} id="cancelBtn">Cancel</div>
          <div onClick={() => { messageToDisplay.func(); }} id="OkayBtn">OK</div>
        </div>
      </div>
    </div>

  );
}

export default CustomAlertBox;
