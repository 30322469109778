import React, { useEffect, useRef, useState } from "react";

import "./ListDownItem.scss";

const ListDownItem = ({ item, index, updateQuantity, deleteItem }) => {
  const [btnColor, setBtnColor] = useState(["#FD6125", "#FD6125"]);
  const InputQuantityRef = useRef();
  const [totalQuantity, setTotalQuantity] = useState(
    item.quantity * item.weight
  );
  const [totalPrice, setTotalPrice] = useState(item.quantity * item.price);

  useEffect(() => {
    InputQuantityRef.current.value = item.quantity;
    setTotalQuantity(item.quantity * item.weight);
    setTotalPrice(item.quantity * item.price);
  }, [item.quantity]);

  const handleChangeQuantity = (quantity) => {
    if (quantity < 1 || isNaN(quantity)) {
      updateQuantity(index, 1);
    } else {
      updateQuantity(index, quantity);
    }
    setTotalQuantity(item.quantity * item.weight);
    setTotalPrice(item.quantity * item.price);
  };

  return (
    <div className="ListDownItem-container">
      <div className="ListDownItem-container__Sr-No">{index + 1}</div>
      <div className="ListDownItem-container__name">{item.name}</div>
      <div className="ListDownItem-container__unit-price">
        {/* Rs {item.price} */}
      </div>
      <div className="ListDownItem-container__weight">{item.weight}g</div>
      <div className="ListDownItem-container__Volumetricweight">
        {item.weight}g
      </div>
      <div className="ListDownItem-container__quantity">
        {item.available_quantity < item.quantity &&
          <div className="ListDownItem-container__quantity__availbleQtyErr"
          >Available Qty={item.available_quantity}</div>}
        <div className="ListDownItem-container__quantity__center">
          <button
            className="ListDownItem-container__quantity__center__minus-plus"
            onMouseOver={() => {
              setBtnColor(["white", "#FD6125"]);
            }}
            onMouseOut={() => {
              setBtnColor(["#FD6125", "#FD6125"]);
            }}
            onClick={() => {
              if (parseInt(InputQuantityRef.current.value) - 1 < 1) {
                handleChangeQuantity(1);
              } else handleChangeQuantity(--InputQuantityRef.current.value);
            }}
          >
            <svg
              width="60%"
              height="10%"
              viewBox="0 0 14 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 1L1 0.999999"
                stroke={btnColor[0]}
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
          {/* {item.quantity} */}
          <input
            className="ListDownItem-container__quantity__center__input"
            type={"number"}
            ref={InputQuantityRef}
            onChange={() => {
              if (InputQuantityRef.current.value === "") {
                handleChangeQuantity(1);
                InputQuantityRef.current.value = 1;
              } else
                handleChangeQuantity(parseInt(InputQuantityRef.current.value));
            }}
          ></input>
          <button
            className="ListDownItem-container__quantity__center__minus-plus"
            onMouseOver={() => {
              setBtnColor(["#FD6125", "white"]);
            }}
            onMouseOut={() => {
              setBtnColor(["#FD6125", "#FD6125"]);
            }}
            onClick={() => {
              handleChangeQuantity(++InputQuantityRef.current.value);
            }}
          >
            <svg
              width="70%"
              height="70%"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 1L7 13"
                stroke={btnColor[1]}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M13 7L1 7"
                stroke={btnColor[1]}
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        {item.available_quantity < item.quantity &&
          <div className="ListDownItem-container__quantity__backorderQtyErr"
          >Back Order Qty={item.quantity-item.available_quantity}</div>}
      </div>
      <div className="ListDownItem-container__totalWeight">
        {totalQuantity}g
      </div>
      <div className="ListDownItem-container__total-price">
        {/* Rs {totalPrice} */}
        <img src="deleteDustbin.svg" alt="delete"
        className="ListDownItem-container__delete-img"
        onClick={()=>{deleteItem(index)}}></img>
        </div>
    </div>
  );
};

export default ListDownItem;
