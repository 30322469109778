import React, { useRef } from "react";
import "./CategoryPopUp.scss";

function CategoryPopUp({ setOpenModal, messageToDisplay }) {
  const modalRef = useRef();

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setOpenModal(false);
    }
  };


  return (
    <div onClick={closeModal} ref={modalRef} className="CategoryPopUp-modalBackground">
      <div className="CategoryPopUp-modalContainer" >
        {/* <div className="custom-alert-box-name">Alert</div> */}
        <div className="CategoryPopUp-modalContainer__box-msg">{messageToDisplay.message}</div>
      </div>
    </div>
  );
}

export default CategoryPopUp;
