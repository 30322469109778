import React, { useEffect, useRef, useState } from "react";
import "./LoadingScreen.scss";

function LoadingScreen({}) {


  return (
    <div className="modalBackground">
      <div className="LoadingScreen__modalContainer" >
        {/* <div className="LoadingScreen__loader"></div> */}
      </div>
    </div>

  );
}

export default LoadingScreen;
