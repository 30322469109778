import React, { useEffect, useState } from "react";
import "./AddressDetails.scss";
import axios from "axios";
import { backEndIP } from "../configuration";


const AddressDetails = ({
    userLocation,
    title,
    label
}) => {
    //console.log(userLocation)

    const locations = {
        "63932c7ab1cab628335ae969": "Jengjal",
        "63930f82865ec3abed90bc1b": "Mendipathar PHC",
        "639310eb865ec3abed90bc1f": "Pedaldoba PHC",
        "639311e2865ec3abed90bc23": "Nongalbibra PHC",
        "6393126c865ec3abed90bc27": "Shallang PHC",
        "639312ff865ec3abed90bc2b": "Samanda PHC",
        "63bd5b3ccafee0c35772f8f1": "Office",
        "639c35f98fa712051b926a36": "India",
        "639c5fe56c0ca3060e173c0c": "North Eastern Zone",
        "639c60e36c0ca3060e173c24": "Meghalaya",
    }
//console.log(userLocation)

    return (
        <div className="MyProfileAddress-SubConatiner__DataConatiner">
            <div className="MyProfileAddress-SubConatiner__DataConatiner__subContainer">
                <div className="MyProfileAddress-SubConatiner__DataConatiner__title">{title}</div>
                <div className="MyProfileAddress-SubConatiner__DataConatiner__label">
                    {label}
                </div>
                <div className="MyProfileAddress-SubConatiner__DataConatiner__value">
                    {`${userLocation?.location_name} (${userLocation.tag_name})`}
                </div>
                <div className="MyProfileAddress-SubConatiner__DataConatiner__label">
                    Country
                </div>
                <div className="MyProfileAddress-SubConatiner__DataConatiner__value">
                    {locations[userLocation.country_id]}
                </div>
                <div className="MyProfileAddress-SubConatiner__DataConatiner__label">
                    Zone
                </div>
                <div className="MyProfileAddress-SubConatiner__DataConatiner__value">
                    {locations[userLocation.zone_id]}
                </div>
                <div className="MyProfileAddress-SubConatiner__DataConatiner__label">
                    State
                </div>
                <div className="MyProfileAddress-SubConatiner__DataConatiner__value">
                    {locations[userLocation.state_id]}
                    {/* <div className="MyProfileDashBoard-SubConatiner__DataConatiner__value__icon" >
                                <span class="material-symbols-outlined">
                                    add
                                </span>
                            </div> */}
                </div>
                <div className="MyProfileAddress-SubConatiner__DataConatiner__label">
                    District
                </div>
                <div className="MyProfileAddress-SubConatiner__DataConatiner__value">
                    {userLocation.district}
                </div>
                <div className="MyProfileAddress-SubConatiner__DataConatiner__label">
                    Address
                </div>
                <div className="MyProfileAddress-SubConatiner__DataConatiner__value">
                    {userLocation.address}
                </div>
                {/* <button className="MyProfileDashBoard-SubConatiner__DataConatiner__chngPassBtn" onClick={() => { setPassWordChange(true) }}>Change Password</button> */}
            </div>
        </div>

    );
};

export default AddressDetails;
