import * as React from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";



export default function BasicDateTimePicker({ deliveryDate, setDeliveryDate }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <label>Start Da</label> */}
      <DatePicker
        renderInput={(props) => (
          <TextField
            {...props}
            sx={{
              ".MuiInputLabel-root": {
                color: "var(--primaryTextColor)",
              },
              ".MuiInputBase-input": {
                padding: "calc(10px * (var(--heightmultiplier) + var(--heightmultiplier)) / 2) ",
                fontSize: "calc(20px * var(--heightmultiplier))",
                fontFamily: "General Sans",
                color: "var(--primaryTextColor)",
              },
              ".MuiInputLabel-outlined": {
                fontSize: "calc(20px * var(--heightmultiplier))",
                fontFamily: "General Sans",
                color: "var(--primaryTextColor)",
              },
              ".MuiSvgIcon-root": {
                // padding: "10px",
                fontSize: "calc(30px * var(--heightmultiplier))",
                // fontFamily:"General Sans",
                //fill: "#FD6125",
                fill: "var(--primaryTextColor)",
              },
              '& .MuiOutlinedInput-root': {
                "& fieldset": {
                  borderColor: "var(--primaryTextColor)",
                  fontSize: "calc(20px * var(--heightmultiplier))",
                  fontFamily: "General Sans",
                  color:"var(--primaryTextColor)"
                },
                "&:hover fieldset": {
                  borderColor: "#FD6125",
                  color:"#FD6125"
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#FD6125",
                  color:"#FD6125"
                },
              }
            }}
            
            onMouseOver={(e) => { e.currentTarget.style.borderColor = "red" }}
          />
        )}
        minDate={dayjs(new Date()).add(1, "day").hour(18).minute(0)}
        // minTime={dayjs().add(1, "day").hour(18).minute(0)}
        // maxTime={dayjs().add(2, "day").hour(18).minute(0)}
        disablePast={true}
        label="Schedule Delivery Time"
        value={deliveryDate}
        required
        inputFormat="DD/MM/YYYY hh:mm a"
        onChange={(newValue) => {
          setDeliveryDate(dayjs(newValue).hour(18).minute(0));
        }}

      />
    </LocalizationProvider>
  );
}


// import React, { useState, useEffect } from "react";
// // import "./styles.css";
// import { makeStyles } from "@material-ui/core";

// import { default as DatePick } from "react-date-picker";

// const useStyles = makeStyles({
//   input: {
//     width:"400px",
//     fontFamily: "sans-serif",
//     fontSize: "14px",
//     height: "50px",
//     "& .react-date-picker__wrapper": {
//       padding: "0 10px",
//       borderColor: "#ccc",
//       borderRadius: "4px"
//     },
//     "& .react-date-picker--open": {
//       borderColor: "red"
//     }
//   },
//   label: {
//     width: "100px",
//     marginTop: "-10px",
//     marginLeft: "12px",
//     backgroundColor: "white",
//     position: "absolute",
//     zIndex: "2000",
//     fontSize: "20px",
//     fontFamily: "sans-serif",
//     paddingLeft: "10px",
//     color:"red"
//   }
// });

// export default function BasicDateTimePicker() {
//   const classes = useStyles();
//   const [value, onChange] = useState(new Date("02/22/2021"));

//   return (
//     <div>
//       {/* <h3>Material UI-Style Label</h3>
//       <h2>on react-date-picker</h2> */}
//       <div>
//         <label className={classes.label}>Start Da</label>
//         <DatePick
//           minDate={new Date()}
//           value={value}
//           onChange={onChange}
//           className={classes.input}
//         />
//       </div>
//     </div>
//   );
// }
