import axios from "axios";
import React, { useEffect, useState } from "react";
import { backEndIP } from "../configuration";
import CustomAlertBox from "../CustomAlert/CustomAlertBox";
import DeleteItem from "../DeleteForm/DeleteForm";
import UpdateForm from "../InventoryUpdateFormPopup/InventoryUpdateForm";
import InventoryUpdatePopup from "../InventoryUpdatePopup/InventoryUpdatePopup";
import "./inventoryTable.scss";

const InventoryTabel = ({
  userCookie,
  SetLoadingVisibility,
  handleNotifyAlert,
}) => {
  const [listItems, setListItems] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [showConfirm, setShowConform] = useState(false);
  useEffect(() => {
    SetLoadingVisibility(true)
    axios({
      url: backEndIP + "/inventory/get-all-items",
      method: "GET",
      headers: {
        authorization: userCookie.token,
        category: "",
      },
    }).then((data) => {
      SetLoadingVisibility(false)
      //console.log(data.data);
      setListItems(data.data.data.sort((a,b)=>(a.serial_no > b.serial_no ? 1 : -1)));
      // setCategory(data.data.data[0].name)
    });
  }, []);
  //console.log(listItems);
  const handleUpdate = (item) => {
    //console.log(item)
    setCurrentItem(item)
    setShowPopup(true)
  }
  const handleDelete = (item) => {
    //console.log(item)
    setCurrentItem(item)
    setShowConform(true)
  }

  return (
    <div className="InventoryTable">
      <div className="InventoryTable__heading">
        <div className="InventoryTable__heading__tabel">
          <div style={{ width: "80px" }} className="InventoryTable__heading__tabel__item">S.No</div>
          <div style={{ width: "200px" }} className="InventoryTable__heading__tabel__item">Image</div>
          <div style={{ width: "400px" }} className="InventoryTable__heading__tabel__item">Name</div>
          <div style={{ width: "200px" }} className="InventoryTable__heading__tabel__item">Category</div>
          <div style={{ width: "200px" }} className="InventoryTable__heading__tabel__item">Added By</div>
          <div style={{ width: "150px" }} className="InventoryTable__heading__tabel__item">Weight</div>
          <div style={{ width: "150px" }} className="InventoryTable__heading__tabel__item">Quantity</div>
          <div style={{ width: "150px" }} className="InventoryTable__heading__tabel__item">Update</div>
          <div style={{ width: "100px" }} className="InventoryTable__heading__tabel__item">Delete</div>
        </div>


      </div>
      <div className="InventoryTable__heading__rows">
        {listItems.map((item, i) => {
          return (
            //  <div className="InventoryTable__heading__rows__itemData">
            <div className="InventoryTable__heading__rows__itemRow" key={i + 1}>
              <div style={{ width: "80px" }} className="InventoryTable__heading__rows__itemRow__item">{i + 1}</div>
              <div style={{ width: "200px" }} className="InventoryTable__heading__rows__itemRow__item">
                <img src={backEndIP + "/" + item.image_name} alt="Item Image" className="InventoryTable__heading__rows__itemRow__item__image" /></div>
              <div style={{ width: "400px" }} className="InventoryTable__heading__rows__itemRow__item">{item.name}</div>
              <div style={{ width: "200px" }} className="InventoryTable__heading__rows__itemRow__item">{item.category}</div>
              <div style={{ width: "200px" }} className="InventoryTable__heading__rows__itemRow__item">{item.added_by.split("@")[0]}</div>
              <div style={{ width: "150px" }} className="InventoryTable__heading__rows__itemRow__item">{item.weight}g</div>
              <div style={{ width: "150px" }} className="InventoryTable__heading__rows__itemRow__item">{item.available_quantity}</div>
              <div style={{ width: "150px" }} className="InventoryTable__heading__rows__itemRow__item"><button className="InventoryTable__heading__rows__itemRow__item__updateButton" onClick={() => handleUpdate(item)}>Edit</button></div>
              <div style={{ width: "100px" }} className="InventoryTable__heading__rows__itemRow__itemEnd">
                {/* <div style={{ color: "green" }} className="InventoryTable__heading__rows__itemRow__itemEnd__action"><span class="material-symbols-outlined">edit</span></div> */}
                <div className="InventoryTable__heading__rows__itemRow__itemEnd__delete" onClick={() => handleDelete(item)}><span className="material-symbols-outlined" style={{fontSize:"calc(28px * var(--heightmultiplier))"}}>delete</span></div>
              </div>
            </div>
            // </div>
          )
        })}
      </div>
      {showConfirm ? <InventoryUpdatePopup setOpenModal={setShowConform} messageToDisplay={{ message: <DeleteItem props={currentItem} setShowConform={setShowConform} userCookie={userCookie} SetLoadingVisibility={SetLoadingVisibility} handleNotifyAlert={handleNotifyAlert} setListItems={setListItems} /> }} /> : null}
      {showPopup ? <InventoryUpdatePopup setOpenModal={setShowPopup} messageToDisplay={{ message: <UpdateForm props={currentItem} setShowPopup={setShowPopup} userCookie={userCookie} SetLoadingVisibility={SetLoadingVisibility} handleNotifyAlert={handleNotifyAlert} setListItems={setListItems}/>, func: () => { } }} /> : null}
    </div>
  );
};

export default InventoryTabel;
//() => handleDelete(item._id)