import React from 'react'
import styled from 'styled-components'


const CategoryBoxDiv = styled.div`
    // width: 100%;
    height: calc(45px * var(--heightmultiplier));
    background-color: var(--primaryColor);
    // border: 1px solid var(--secondaryColor);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(22px * var(--heightmultiplier));;
    &:hover{
        cursor:pointer;
    }
    `
const SelectedCategoryBoxDiv = styled.div`
    width: 100%;
    height: calc(45px * var(--heightmultiplier));
    background-color: rbga(0,0,0,0.5);
    border: 1px solid var(--secondaryColor);
    overflow: hidden;
    background-color: rgba(253,97,37,0.1);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(22px * var(--heightmultiplier));
    border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
    &:hover{
        cursor:pointer;
    }
    // &::before{
    //     width: 100%;
    // height: calc(45px * var(--heightmultiplier));
    // background-color: rbga(50,50,50,0.5);
    // top:0px;
    // left:0px;
    // }
    `


function CategoryListItem({ categoryName, selectedCategory, setSelectedCategory, setSelectedSubContent }) {

    const handleCategorySelection = () => {
        setSelectedCategory(categoryName);
        setSelectedSubContent("")
    }

    return (
        <>
            {selectedCategory !== categoryName && <CategoryBoxDiv
                onClick={handleCategorySelection}
            >
                <div style={{ color: "var(--primaryTextColor)", opacity: 0.4 }}
                >
                    {categoryName}
                </div>
            </CategoryBoxDiv>}
            {selectedCategory === categoryName &&
                <SelectedCategoryBoxDiv
                onClick={handleCategorySelection}>
                    <div style={
                        {
                            color: "#FD6125",
                            border: "1px solid #FD6125",
                            height: "calc(15px * var(--heightmultiplier))",
                            marginRight: "calc(22px * var(--widthmultiplier))",
                            borderRadius: "1px"
                        }
                    }></div>
                    <div style={
                        {
                            color: "#FD6125",
                            fontSize: ""
                        }
                    }>{categoryName}</div>
                </SelectedCategoryBoxDiv>
            }
        </>
    )
}

export default CategoryListItem