import React, { useEffect, useRef, useState } from 'react'
import "./OrderStatusIndicator.scss"
const OrderStatusIndicator = ({ status, refresh }) => {
    const [statusNumber, setStatusNumber] = useState(0);
    const timelineBarRef = useRef();

    useEffect(() => {
        if (status) {
            setStatusNumber(status.number)
        }
        // timelineBarRef.current.style = { width: `${14.2857 * (5 - 1)}%` };

        // const id = setTimeout(() => {
        //     timelineBarRef.current.style.width = `${14.2857 * (statusNumber - 1)}%`;
        // }, 1000);
        timelineBarRef.current.style.width = `${14.2857 * (status.number - 1)}%`;

        // return ()=>{clearTimeout(id)}

    }, [status, refresh])


    return (
        <div className='OrderStatusIndicator-container'>
            <div className='OrderStatusIndicator-container__timeline'>
                <div className='OrderStatusIndicator-container__timeline__bar'
                    ref={timelineBarRef}
                ></div>
            </div>
            {statusNumber > 8 && <div className='OrderStatusIndicator-container__status-tags'>
                <div className='OrderStatusIndicator-container__status-tags__placed__tag'
                    style={{ fontWeight: statusNumber >= 1 ? "500" : "400" }}
                >Canceled</div>
            </div>
            }
            {statusNumber <= 8 && <div className='OrderStatusIndicator-container__status-tags'>
                <div className='OrderStatusIndicator-container__status-tags__placed'>
                    <div className='OrderStatusIndicator-container__status-tags__placed__circle'
                        style={{ backgroundColor: statusNumber >= 1 ? "#FD6125" : "#FDD5C5" }}
                    ></div>
                    <div className='OrderStatusIndicator-container__status-tags__placed__tag'
                        style={{ fontWeight: statusNumber >= 1 ? "500" : "400" }}
                    >Placed</div>
                </div>
                <div className='OrderStatusIndicator-container__status-tags__statusMap'>
                    <div className='OrderStatusIndicator-container__status-tags__statusMap__name'>
                        <div className='OrderStatusIndicator-container__status-tags__statusMap__circle'
                            style={{ backgroundColor: statusNumber >= 2 ? "#FD6125" : "#FDD5C5" }}
                        ></div>
                        <div className='OrderStatusIndicator-container__status-tags__statusMap__tag'
                            style={{ fontWeight: statusNumber >= 2 ? "500" : "400" }}
                        >Approved</div>
                    </div>
                    <div className='OrderStatusIndicator-container__status-tags__statusMap__name'>
                        <div className='OrderStatusIndicator-container__status-tags__statusMap__circle'
                            style={{ backgroundColor: statusNumber >= 3 ? "#FD6125" : "#FDD5C5" }}
                        ></div>
                        <div className='OrderStatusIndicator-container__status-tags__statusMap__tag'
                            style={{ fontWeight: statusNumber >= 3 ? "500" : "400" }}
                        >Packed</div>
                    </div>
                    <div className='OrderStatusIndicator-container__status-tags__statusMap__name'>
                        <div className='OrderStatusIndicator-container__status-tags__statusMap__circle'
                            style={{ backgroundColor: statusNumber >= 4 ? "#FD6125" : "#FDD5C5" }}
                        ></div>
                        <div className='OrderStatusIndicator-container__status-tags__statusMap__tag'
                            style={{ fontWeight: statusNumber >= 4 ? "500" : "400" }}
                        >Sent from Hub</div>
                    </div>
                    <div className='OrderStatusIndicator-container__status-tags__statusMap__name'>
                        <div className='OrderStatusIndicator-container__status-tags__statusMap__circle'
                            style={{ backgroundColor: statusNumber >= 5 ? "#FD6125" : "#FDD5C5" }}
                        ></div>
                        <div className='OrderStatusIndicator-container__status-tags__statusMap__tag'
                            style={{ fontWeight: statusNumber >= 5 ? "500" : "400" }}
                        >Intransit</div>
                    </div>
                    <div className='OrderStatusIndicator-container__status-tags__statusMap__name'>
                        <div className='OrderStatusIndicator-container__status-tags__statusMap__circle'
                            style={{ backgroundColor: statusNumber >= 6 ? "#FD6125" : "#FDD5C5" }}
                        ></div>
                        <div className='OrderStatusIndicator-container__status-tags__statusMap__tag'
                            style={{ fontWeight: statusNumber >= 6 ? "500" : "400" }}
                        >Received at Local Station</div>
                    </div>
                    <div className='OrderStatusIndicator-container__status-tags__statusMap__name'>
                        <div className='OrderStatusIndicator-container__status-tags__statusMap__circle'
                            style={{ backgroundColor: statusNumber >= 7 ? "#FD6125" : "#FDD5C5" }}
                        ></div>
                        <div className='OrderStatusIndicator-container__status-tags__statusMap__tag'
                            style={{ fontWeight: statusNumber >= 7 ? "500" : "400" }}
                        >Sent out for delivery</div>
                    </div>
                </div>
                <div className='OrderStatusIndicator-container__status-tags__delivered'>
                    <div className='OrderStatusIndicator-container__status-tags__delivered__circle'
                        style={{ backgroundColor: statusNumber >= 8 ? "#FD6125" : "#FDD5C5" }}
                    ></div>
                    <div className='OrderStatusIndicator-container__status-tags__delivered__tag'
                        style={{ fontWeight: statusNumber >= 8 ? "500" : "400" }}
                    >Delivered</div>
                </div>

            </div>}
        </div>
    )
}

export default OrderStatusIndicator