import axios from 'axios';
import React, { useEffect, useState } from 'react'
import OrdersListItem from '../OrdersListItem/OrdersListItem';
import "./OrdersListSubContent.scss"
import { backEndIP } from '../configuration'
import { useNavigate } from 'react-router-dom';

const OrdersListSubContent = ({ userCookie ,setContentOption,setOrderNo,SetLoadingVisibility, handleNotifyAlert}) => {
  const [OrdersList, setOrdersList] = useState([])
  const navigate = useNavigate();
  useEffect(() => {
    if (userCookie.token === "" || !userCookie.token) {
      navigate("/");
      return;
    } 
    SetLoadingVisibility(true);
    axios({
      url: backEndIP + "/order/orders-list",
      method: "GET",
      headers: {
        authorization: userCookie.token,
      },
      // data: { id: product._id , quantity:1 }
    })
      .then((resp) => {
        //console.log(resp.data);
        SetLoadingVisibility(false);
        setOrdersList(resp.data.data.order);
      })
      .catch((err) => {
        SetLoadingVisibility(false);
        if (err.response.data.message) window.alert(err.response.data.message);
        else window.alert("some error occurered");
        //console.log(err);
      });
  }, [])

 

  return (
    <div className='OrdersListSubContent-container'>
      <div className='OrdersListSubContent-container__title'>
        Orders List
      </div>
      <div className='OrdersListSubContent-container__totalItems'>
        Total Orders : {OrdersList.length}
      </div>

      <div className='OrdersListSubContent-container__subContainer'>
        <div className='OrdersListItems-container' >
          <div className='OrdersListItems-container__Sr-No'>S.No</div>
          <div className='OrdersListItems-container__order-no'>Order ID</div>
          <div className='OrdersListItems-container__totalWeight'>Total Weight</div>
          <div className='OrdersListItems-container__total-quantity'>Total Quantity</div>
          {/* <div className='OrdersListItems-container__total-price'>Total Price</div> */}
          <div className='OrdersListItems-container__status'>Status</div>
        </div>
        <div className='OrdersListSubContent-container__subContainer__listDown'>
          <div>
            {OrdersList.map((item, i) => { return <OrdersListItem item={item} index={i} key={`${item.name} ${i}`}
            setContentOption={setContentOption} setOrderNo={setOrderNo}/> })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrdersListSubContent