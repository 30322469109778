import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'


const InfoBoxTemplate = styled.div`
    width: calc(${"30%"} * var(--widthmultiplier));
    height: calc(${"30%"} * var(--heightmultiplier));
    border-radius: calc(10px * ((var(--widthmultiplier) + var(--heightmultiplier)) / 2));
    background-color: var(--primaryColor);
    border: 1px solid var(--secondaryTextColor);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    `

const setContainer = (_width, _height) => {
    InfoBoxTemplate = styled.div`
        width: calc(${_width} * var(--widthmultiplier));
        height: calc(${_height} * var(--heightmultiplier));
        border-radius: calc(10px * ((var(--widthmultiplier) + var(--heightmultiplier)) / 2));
        background-color: var(--primaryColor);
        border: 1px solid var(--secondaryTextColor);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        `
}
const InfoBoxLabel = styled.div`
    margin-top:0.3em;
    font-size: 1em;
    `

const InfoBoxValue = styled.div`
    font-size: 2em;
    width: 80%;
    height: 50%;
    margin-bottom: 0.5em;
    background-color: var(--secondaryColor);
    border-radius: 0.3em;
    display: flex;
    align-items: center;
    justify-content: center;
    `

function InfoIndicatorBox({ infoLabel, infoValue, _width, _height }) {

    return (
        <InfoBoxTemplate>
            <InfoBoxLabel>
                {infoLabel}
            </InfoBoxLabel>
            <InfoBoxValue >
                {infoValue}
            </InfoBoxValue>
        </InfoBoxTemplate>
    )
}

export default InfoIndicatorBox