import React, { useRef, useState } from 'react'
import "./ForgotPassword.scss"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { backEndIP } from '../configuration';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import CustomNotification from '../CustomNotification/CustomNotification';

const ForgotPassword = (props) => {
    const { setuserCookie, userCookie, themeGlobal, setUserName } = props;

    const OTPElementRef = useRef();
    const userEmailElementRef = useRef();
    const passwordElementRef = useRef();
    const confirmPasswordElementRef = useRef();
    const errorMessageElementRef = useRef();
    const navigate = useNavigate();
    const [loadingVisibility, setLoadingVisibility] = useState(false);
    const [notificationVisibility, setNotificationVisibility] = useState(false);
    const [messageToDisplay, setMessageToDisplay] = useState({
        type: "notify",
        message: "msg",
        msgtype: "success",
        func: () => { },
    });

    const handleUpdateAlertNottifyMsg = (type, message, msgtype, func) => {
        setMessageToDisplay({
            type: type,
            message: message,
            msgtype: msgtype,
            func: () => {
                setLoadingVisibility(false);
                func();
            },
        });
        if (type === "notify") {
            setNotificationVisibility(true);
        } else if (type === "alert") {
            setNotificationVisibility(true);
        }
    };

    const handleChangePasswordBtnClick = () => {
        let otp = OTPElementRef.current.value;
        let userEmail = userEmailElementRef.current.value;
        let password = passwordElementRef.current.value;
        let confirmPassword = confirmPasswordElementRef.current.value;
        // console.log(otp, password, confirmPassword)

        if (password.length > 5 && userEmail.length > 5) {
            if (password === confirmPassword && password.length >= 6) {
                errorMessageElementRef.current.style.opacity = 0;
                axios({
                    // url: "https://dineshborse.in/user/register",
                    url: backEndIP + "/user/change-password",
                    method: "POST",
                    headers: {
                    },
                    data: { useremail: userEmail, otp: otp, password: password }
                }).then(async (loginData) => {
                    if (loginData.data.status === "success") {
                        handleUpdateAlertNottifyMsg("alert", `${loginData.data.message}`, "success", () => {navigate("/") })
                        // window.alert(loginData.data.message)
                    }
                    console.log(loginData.data)
                    

                }).catch((err) => {
                    // window.alert(err.response.data.message)
                    handleUpdateAlertNottifyMsg("alert", `${err.response.data.message}`, "failed", () => { })
                    console.log(err);
                })
            }
            else {
                if (password.length < 6) {
                    errorMessageElementRef.current.innerHTML = "minimum length of password should be 6"
                    errorMessageElementRef.current.style.opacity = 1;
                }
                else {
                    errorMessageElementRef.current.innerHTML = "password does not match"
                    errorMessageElementRef.current.style.opacity = 1;
                }

            }
        }
        else {

            errorMessageElementRef.current.innerHTML = "pls enter password"
            errorMessageElementRef.current.style.opacity = 1;
        }
    }
    const [isOTPSent, setIsOTPSent] = useState(false);
    const handleSendOTPBtnClick = () => {
        let userEmail = userEmailElementRef.current.value;
        if (!isOTPSent && userEmail.length >= 6) {
            setLoadingVisibility(true);
            setIsOTPSent(true)
            axios({
                // url: "https://dineshborse.in/user/register",
                url: backEndIP + "/user/get-otp",
                method: "POST",
                headers: {
                },
                data: { useremail: userEmail }
            }).then(async (loginData) => {
                setLoadingVisibility(false);
                if (loginData.data.status === "success") {
                    handleUpdateAlertNottifyMsg("notify", `${loginData.data.message}`, "success", () => { })
                    // window.alert(loginData.data.message)
                }
                console.log(loginData.data)
                // navigate("/")

                setTimeout(() => {
                    setIsOTPSent(false)
                }, 60000);

            }).catch((err) => {
                setLoadingVisibility(false);
                handleUpdateAlertNottifyMsg("alert", `${err.response.data.message}`, "failed", () => { })
                // window.alert(err.response.data.message)
                console.log(err);
                setIsOTPSent(false)
            })

        }
        else {
            setLoadingVisibility(false);
            if (userEmail.length < 6) {
                // window.alert("Invalid email entered")
                handleUpdateAlertNottifyMsg("notify", `Invalid email entered. Please Enter valid email`, "failed", () => { })
            }
            else {
                handleUpdateAlertNottifyMsg("alert", `Otp Already sent to you email, If not recieved retry after 1 minute`, "failed", () => { })
                // window.alert("Otp Already sent to you email, If not recieved retry after 1 minute")
            }
        }

    }

    return (
        <div className="forgotpassword-page" style={{ height: "100vh", width: "100vw" }}>
            <div className="forgotpassword-page__leftHalf">
                <div className="forgotpassword-page__leftHalf__welcomeBox">
                    <img className="forgotpassword-page__leftHalf__welcomeBox__logo"
                        src={themeGlobal.EagleLogo} alt="logo"
                    >
                    </img>
                    <div className="forgotpassword-page__leftHalf__welcomeBox__welcomeMsg">Welcome</div>
                    <div className="forgotpassword-page__leftHalf__welcomeBox__register-message">Reset Password</div>
                </div>
                <div className="forgotpassword-page__leftHalf__otp-container">

                    <input ref={userEmailElementRef} className="forgotpassword-page__leftHalf__otp-container__input-password" type={"email"} placeholder={"User Email Id"}></input>
                    {isOTPSent && <div className="forgotpassword-page__leftHalf__otp-container__spinner"></div>}
                    <button onClick={handleSendOTPBtnClick}
                        className="forgotpassword-page__leftHalf__otp-container__btn-getOTP">Get OTP</button>
                </div>
                <div className="forgotpassword-page__leftHalf__register-container">

                    <input ref={OTPElementRef} className="forgotpassword-page__leftHalf__register-container__input-email" type={"text"} placeholder={"OTP"}></input>
                    <input ref={passwordElementRef} className="forgotpassword-page__leftHalf__register-container__input-password" type={"password"} placeholder={"Password"}></input>
                    <input ref={confirmPasswordElementRef} className="forgotpassword-page__leftHalf__register-container__input-email" type={"password"} placeholder={"Confirm Password"}></input>
                    {/* <div className="login-page-body__leftHalf__login-container__forgotPassword">Forgot password?</div> */}
                    <button onClick={handleChangePasswordBtnClick}
                        className="forgotpassword-page__leftHalf__register-container__btn-SignIn">Change Password</button>
                    <div
                        className="forgotpassword-page__leftHalf__register-container__register-msg1">

                        <span
                            className="forgotpassword-page__leftHalf__register-container__register-msg2"
                            onClick={() => { navigate("/") }}
                        >Login
                        </span>
                        &nbsp;Page
                    </div>
                    <div ref={errorMessageElementRef}
                        className="forgotpassword-page__leftHalf__register-container__error-msg"
                    >message</div>
                </div>
            </div>
            <div className="forgotpassword-page__rightHalf">
                <img className="forgotpassword-page__rightHalf__img"
                    src="drone_in_ancient_cave_processed.png" alt="img"></img>
            </div>
            {notificationVisibility && (
                <CustomNotification
                    setOpenModal={setNotificationVisibility}
                    messageToDisplay={messageToDisplay}
                />
            )}
            {loadingVisibility && <LoadingScreen />}
        </div>
    )
}

export default ForgotPassword