import React, { useEffect, useRef, useState } from "react";
import "./CancellationOrderTemplateContent.scss";
import axios from "axios";
import { backEndIP } from "../configuration";

const CancellationOrderTemplateContent = ({ setCancellationPopup,
    setShowPopup,
    orderData,
    SetLoadingVisibility,
    userCookie,
    handleNotifyAlert,
    setUpdateSelf,
    orderListItems,
    orderListItemCopy,
    updateOrderDetailsData,
    index }) => {
    const [cancellationReason, setCancellationReason] = useState("")
    const [reasonsForOrderCancellation, setReasonsForOrderCancellation] = useState("Select")
    const [errorOrderCancel, setErrorOrderCancel] = useState(false)
    const [errorOrderComment, setErrorOrderComment] = useState(false)

    const handleCancelOrderBtn = () => {
        if (reasonsForOrderCancellation === "Select") {
            setErrorOrderCancel(true)
        } else {
            if (reasonsForOrderCancellation === "Other" && cancellationReason === "") {
                setErrorOrderComment(true)
            } else {
                // console.log('item',)
                SetLoadingVisibility(true)
                const orderCancelReason = `${reasonsForOrderCancellation}: ${cancellationReason}`
                axios({
                    url: backEndIP + "/order/cancel_order",
                    method: "PUT",
                    headers: {
                        authorization: userCookie.token,
                    },
                    data: {
                        order_id: orderData._id,
                        statusNumber: 9,
                        statusName: "Canceled",
                        cancellationReason: orderCancelReason
                    }
                }).then((resp) => {
                    //console.log(resp.data.data)
                    SetLoadingVisibility(false)
                    // setCancelOrderConfirm(false);
                    handleNotifyAlert(
                        "notify",
                        `Order Cancelled successfully`,
                        "success",
                        () => { }
                    );
                    // orderListItems[index] = resp.data.data
                    // orderListItemCopy[index] = resp.data.data
                    //console.log(resp.data.data)
                    updateOrderDetailsData(resp.data.data)
                    // setUpdateSelf(prev => !prev)
                    SetLoadingVisibility(false)
                    setCancellationPopup(false)
                    setShowPopup(false)
                })
                    .catch((err) => {
                        //console.log(err);
                        SetLoadingVisibility(false);
                        // setCancelOrderConfirm(false);
                        handleNotifyAlert(
                            "notify",
                            `${err.response.data.message}`,
                            "failed",
                            () => { }
                        );
                    });
            }
        }

    }

    return (
        <div className="cancellationOrderTemplateContent">
            <div className="cancellationOrderTemplateContent__title">Order No :{orderData.order_no}</div>
            <div className="cancellationOrderTemplateContent__inputFiled">
                <div className="cancellationOrderTemplateContent__inputFiled__label">Reason For Cancellation<div style={{ color: "red" }}> * </div></div>
                <div className="cancellationOrderTemplateContent__inputFiled__value">
                    <select type="text"
                        value={reasonsForOrderCancellation}
                        // placeholder="Enter Product Name"
                        className="cancellationOrderTemplateContent__inputFiled__value__input"
                        onChange={(e) => { setReasonsForOrderCancellation(e.target.value); setErrorOrderCancel(false) }}
                    >
                        <option >
                            Select
                        </option>
                        <option value={"Users request to cancel the order"}>
                            Users request to cancle the order
                        </option>
                        <option value={"Other"}>
                            Other
                        </option>
                    </select>
                </div>
            </div>
            {errorOrderCancel && <div className="cancellationOrderTemplateContent__error">Please select the reason for cancellation</div>}
            <div className="cancellationOrderTemplateContent__inputFiled">
                <div className="cancellationOrderTemplateContent__inputFiled__label">Reason Details <div style={{ color: "red" }}> * </div></div>
                <div className="cancellationOrderTemplateContent__inputFiled__value">
                    <textarea type="texts"
                        value={cancellationReason}
                        cols="50"
                        rows="4"
                        // variant="outlined"
                        placeholder="Please Enter The Reason For Cancellation"
                        className="cancellationOrderTemplateContent__inputFiled__value__input"
                        style={{ height: "calc(100px * var(--heightmultiplier)", resize: "none" }}
                        onChange={(e) => { setCancellationReason(e.target.value); setErrorOrderComment(false) }}
                    /></div>
            </div>
            {errorOrderComment && <div className="cancellationOrderTemplateContent__error">Please enter the reason for cancellation</div>}
            <div className="cancellationOrderTemplateContent__button">
                <button className="cancellationOrderTemplateContent__button__orderCancel" onClick={handleCancelOrderBtn}>Cancel order</button></div>
        </div>
    );
}

export default CancellationOrderTemplateContent;
